import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/auth-context";

import classes from "./Bonbon.module.css";
import { database } from "../../../../../../utility/firebase";
import * as DB from "firebase/database";

import smallArrow from "../../../../../UI/svgs/smallArrow.svg";
import PageContext from "../../../../../../context/pageStatusContext";
import Profit from "../Profit/Profit";
function Bonbon(props) {
  const [profit, setProfit] = useState(0);

  const ctx = useContext(AuthContext);
  const [stat, setStat] = useState(0);
  const ctxPage = useContext(PageContext);
  function goToSettings() {
    ctxPage.settingsStatusChanger(true);
  }
  useEffect(() => {
    let starCountRef = DB.ref(
      database,
      `userSignals/2H8paN9F7xUEOBTVyaWyn8EYpLn2/transactions`
    );
    // console.log("dataObject");
    let recentTrueSignals = DB.query(
      starCountRef,
      DB.orderByChild("return"),
      DB.equalTo(true)
    );
    DB.onValue(recentTrueSignals, (snapshot) => {
      const dataTrueObject = Object.entries(snapshot.val()).reverse();

      let recentFalseSignals = DB.query(
        starCountRef,
        DB.orderByChild("return"),
        DB.equalTo(false)
      );
      DB.onValue(recentFalseSignals, (snapshot) => {
        const dataFalseObject = Object.entries(snapshot.val()).reverse();
        setProfit(
          (dataTrueObject.length - dataFalseObject.length) * ctx.maxUSDT * 0.04
        );
        const result =
          (dataTrueObject.length /
            (dataTrueObject.length + dataFalseObject.length)) *
          100;
        const roundedResult = result.toFixed(2); // "63.16"
        setStat(roundedResult);
      });
      // .length * ctx.maxUSDT * 0.04
    });
  }, [ctx.maxUSDT]);

  return (
    <div className={`${classes.statusHolder} ${props.className}`}>
      <div className={`${classes.statusLine} ${classes.statusLineEarned}`}>
        <span className={classes.statusText}>
          Possible profit since January 2023:{" "}
        </span>
        <span className={classes.madeCoin}>
          <Profit count={profit} /> $
        </span>
      </div>

      <div>
        <span className={classes.statusText}>
          <span className={classes.goToSettingsLine} onClick={goToSettings}>
            <span className={classes.goToSettingsText}>
              Start your AI investments now
            </span>
          </span>
        </span>
      </div>
    </div>
  );
}
//TODO License Expired should be visible when license days 0
// TODO add the link to the license on setting
export default Bonbon;
