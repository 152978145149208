import * as DB from "firebase/database";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../context/auth-context";
import { database } from "../../../../../utility/firebase";

import InfiniteScrollCustom from "./InfiniteScrollCustom";

let dataAmount = 0;
export default function HistoryNewMissed(props) {
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(data.length);
  const ctx = useContext(AuthContext);

  let starCountRef = DB.ref(database, `userSignals/${ctx.branch}/transactions`);
  useEffect(() => {
    handleNewData();
  }, []);
  const handleNewData = (filter) => {
    dataAmount += 50;
    let recentSignals = DB.query(
      starCountRef,
      DB.orderByChild("status"),
      DB.equalTo("canceled"),
      DB.limitToLast(dataAmount)
    );
    DB.onValue(recentSignals, (snapshot) => {
      // check if snap is null - this was implemented to reduce errors in log
      const snap = snapshot.val();
      if (!snap) {
        return null;
      }
      const dataObject = Object.entries(snapshot.val()).reverse();
      // console.log(dataObject);
      setData(dataObject);
    });
  };

  return <InfiniteScrollCustom data={data} handleNewData={handleNewData} />;
}
