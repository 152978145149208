import { useState } from "react";
import MainButtonIntro from "./Button/MainButtonIntro";
import classes from "./screen.module.css";
import { motion as m, AnimatePresence } from "framer-motion";
function ScreenOne() {
  const [clicked, setClicked] = useState(true);
  function clickHandler() {
    clicked &&
      setTimeout(() => {
        setClicked(false);
      }, 2000);
  }
  return (
    <AnimatePresence mode={"wait"} initial={false}>
      <m.div className={classes.background}>
        <div className={`${classes.introScreen} wrapper`}>
          {clicked ? (
            <div className={classes.h1Holder}>
              <m.h1
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                Turn on the AI trader 🤖
              </m.h1>
            </div>
          ) : (
            <AnimatePresence>
              <div className={classes.h1Holder}>
                <m.h1
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* It’s easier than you think 👋 */}
                  AI predicts which coin will raise.
                </m.h1>
              </div>
            </AnimatePresence>
          )}
          <div className={classes.buttonHolder}>
            {clicked && (
              <MainButtonIntro click={clickHandler} invisible={true} />
            )}
            {!clicked && (
              <MainButtonIntro click={clickHandler} invisible={false} />
            )}
          </div>

          {clicked ? (
            <span className={classes.textAndButton}>
              <span>Just press the button</span>
            </span>
          ) : (
            <span className={classes.textAndButton}>
              <m.span
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.5 }}
              >
                Drag the small circle and decide how much USDT to invest per
                signal. Make sure you have enough USDT on your Binance account.
              </m.span>
            </span>
          )}
        </div>
      </m.div>
    </AnimatePresence>
  );
}

export default ScreenOne;
