import React, { useContext } from "react";
import AuthContext from "../../../context/auth-context";
import PageContext from "../../../context/pageStatusContext";
import classes from "./TwitterButton.module.css";
import { auth } from "../../../utility/firebase";
import twitter from "../../UI/svgs/twitter.svg";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { version } from "../../../utility/settings";
import { signInfunc } from "../../../utility/settings";

const TwitterButton = (props) => {
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);
  const twitterProvider = new TwitterAuthProvider();

  async function twitterSignin() {
    try {
      const result = await signInWithPopup(auth, twitterProvider);
      const token = result._tokenResponse;
      const idToken = token.idToken;
      const localId = token.localId;
      const refreshToken = token.refreshToken;
      token.isNewUser
        ? googleSigninCreateUserInDB(idToken, localId)
        : signInfunc(idToken, localId, ctx, ctxPage);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("localId", localId);
      localStorage.setItem("refT", refreshToken);
    } catch (err) {
      console.log(err);
    }
  }
  function googleSigninCreateUserInDB(idToken, localId) {
    ctxPage.onBoardingChanger(true);
    ctxPage.signUpStatusChanger(false);
    const currentURL = window.location.href;
    let refLinkId = "";
    if (currentURL.length > 26) {
      refLinkId = window.location.href.substring(
        window.location.href.indexOf("?rid=") + 5
      );
    }

    const userCreationDataButton = {
      [localId]: {
        APIkey: "",
        buttonOnOff: false,
        maxUsdt: 100,
        secretKey: "",
        userId: localId,
        onBoarded: false,
        maxInvest: 10000,
        refLink: {
          signUp: refLinkId,
        },
        wallet: "",
      },
    };
    fetch(`${version}/newUser.json/?auth=${idToken}`, {
      method: "PATCH",
      body: JSON.stringify(userCreationDataButton),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => signInfunc(idToken, localId, ctx, ctxPage))
      .catch((err) => console.log(err));
  }

  return (
    <button
      type={props.type || "button"}
      className={`${classes.gbutton} ${props.className}`}
      onClick={twitterSignin}
      disabled={props.disabled}
    >
      <img src={twitter} alt={"appleLogo"} />
      Sign in with Twitter
    </button>
  );
};

export default TwitterButton;
