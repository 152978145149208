export const questions = [
  {
    id: 1,
    question: "What is Callbot?",
    answer:
      "Callbot is an AI-based spot Autotrader. Its big data analyses are performed with the application of three completely different predictive algorithms, which have different mathematical roots: Statistical, deep learning and autoregressive. More info under <a href='www.callbotcrypto.com'>www.callbotcrypto.com</a>",
  },
  {
    id: 2,
    question:
      "Do I need to have a Binance account to use Callbot and its Autotrader?",
    answer: "Yes, the Autotrader works exclusively through the Binance API.",
  },
  {
    id: 3,
    question: "How to start trading?",
    answer:
      "Once your account has been verified, you will need to connect your Binance API to Callbot.",
  },
  {
    id: 4,
    question: "How to connect Callbot with my Binance?",
    answer:
      "In order to connect Callbot with Binance you will need to create an API (if don’t have one) and copy-paste the keys in the respective sections (Settings -> Binance API).",
  },
  {
    id: 5,
    question: "Are my data secure on Callbot? ",
    answer:
      "Yes, all user data is stored on the secure firebase platform provided by Google. Firebase is certified under major privacy and security standards.",
  },
  {
    id: 6,
    question: "How do I subscribe when my trial is over?",
    answer:
      "To subscribe you need to transfer USDT from your Binance account to the Callbot wallet bearing in mind that the fee is 1 €/day.",
  },
  {
    id: 7,
    question: "Who sells and who buys the tokens when I use Callbot?",
    answer:
      "Once your account is activated, all you have to do is observe all the Autotrader's movements on your Callbot dashboard.",
  },
  {
    id: 8,
    question:
      "Are my “personal” tradings going to be affected by the activity of Callbot?",
    answer:
      "No, Callbot only buys and sells the signals deriving from its analyses without affecting any other user's investments in any way.",
  },
  {
    id: 9,
    question: "Which trading pairs are supported by Callbot?",
    answer: "Callbot analyses all the Binance USDT-paired tokens.",
  },
  {
    id: 10,
    question: "In which markets will the Autotrader operate?",
    answer: "The Autotrader invests only in the Spot market.",
  },
  {
    id: 11,
    question:
      "What token do I need in my Binance portfolio to operate the Autotrader?",
    answer:
      "Callbot uses exclusively the USDT in your Spot wallet to invest and earn.",
  },
  {
    id: 12,
    question:
      "The signal was sold at a higher price than the take-profit level. Why?",
    answer:
      "When a token rises in value very quickly, the Autotrader follows the increase and tries to sell at the peak. This feature made it possible to sell signals with a 50% profit.",
  },
  {
    id: 13,
    question:
      "The signal has reached the take profit level but the bot has not liquidated the position yet, why? ",
    answer:
      "The liquidation of a signal is closely linked to the liquidity available on the market. In these cases the best thing to do is to be patient and wait for the signal to be sold at the best price.",
  },
  {
    id: 14,
    question:
      "Does the Autotrader always sell at the take-profit or stop-loss levels?",
    answer:
      "No, the Autotrader analyzes the data of each single token continuously and therefore there can be slight variations in the selling prices. Therefore, take-profit and stop loss-levels should be considered as reference values.",
  },
  {
    id: 15,
    question:
      "I received a signal, but the app says “price already raised”. What does it mean?",
    answer:
      "When a signal arrives, the Autotrader makes an offer on the market. Not all offers are accepted if the price offered is too low. In these cases the order is canceled after a few minutes.",
  },
  {
    id: 16,
    question: "How much will the Autotrader invest for each signal?",
    answer:
      "You can decide how much to invest at any time by dragging the small ball around the button.",
  },
  {
    id: 17,
    question: "How much can the Autotrader invest in total simultaneously?",
    answer:
      "The limit can be set by pressing on the burger at the top left of the button.",
  },
  {
    id: 18,
    question: "How does the referral program work?",
    answer:
      "Each user can generate its referral link. For each friend who subscribes with your referral and subscribes for at least 1 day, you will receive 10$ on your wallet. More info under the referral program section (Settings -> Referral Program).",
  },
  {
    id: 19,
    question: "What happens if I turn the button off?",
    answer:
      "The Autotrader will no longer trade with your Binance account until it is turned on again and if there are any open positions, they will be closed.",
  },
  {
    id: 20,
    question:
      "Why are my profits on the app different from my profits on Binance?",
    answer:
      "Callbot does not take commission fees into account and therefore actual profits on Binance may vary based on the commission fees the user pays on Binance.",
  },
];
