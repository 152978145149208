import React, { useState, useEffect, useContext } from "react";
import backArrow from "../../UI/svgs/backArrow.svg";
import "./Login.css";

import { signInWithEmailAndPassword } from "firebase/auth";

import Button from "../../UI/button/Button";
import AuthContext from "../../../context/auth-context";
import PageContext from "../../../context/pageStatusContext";
import eyeClosed from "../../UI/svgs/eyeClosed.svg";
import eyeOpen from "../../UI/svgs/eyeOpen.svg";
import GoogleButton from "../../UI/button/GoogleButton";
import { motion as m } from "framer-motion";
import { auth } from "../../../utility/firebase";
import TwitterButton from "../../UI/button/Twitterbutton";
import { version } from "../../../utility/settings";
import { signInfunc } from "../../../utility/settings";
const Login = (props) => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [loginFailed, setLoginFailed] = useState();
  const [eyeToggle, setEyeToggle] = useState(eyeClosed);
  const [passwordText, setPasswordText] = useState("password");
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);

  // sign in with google

  function signInWithEmail(event) {
    event.preventDefault();
    signInWithEmailAndPassword(auth, enteredEmail, enteredPassword)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const token = userCredential._tokenResponse;
        if (user.emailVerified) {
          localStorage.setItem("idToken", token.idToken);
          localStorage.setItem("localId", token.localId);
          localStorage.setItem("refT", token.refreshToken);
          signInfunc(token.idToken, token.localId, ctx, ctxPage);
        } else {
          setLoginFailed(
            <span style={{ color: "red" }}>E-mail not verified</span>
          );
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        errorMessage.includes("wrong-password") &&
          setLoginFailed(<span style={{ color: "red" }}>Wrong Password</span>);
        errorMessage.includes("user-not-found") &&
          setLoginFailed(<span style={{ color: "red" }}>User not exist</span>);
      });
  }

  useEffect(() => {
    const identifier = setTimeout(() => {
      setFormIsValid(
        enteredEmail.includes("@") && enteredPassword.trim().length > 6
      );
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [enteredEmail, enteredPassword]);

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  function eyeToggleHandler() {
    if (passwordText === "password") {
      setEyeToggle(eyeOpen);
      setPasswordText("text");
    } else {
      setEyeToggle(eyeClosed);
      setPasswordText("password");
    }
  }
  /// Registration and Password reset possibility

  return (
    <m.div className="pageHolder wrapper" {...ctxPage.animation}>
      <img
        src={backArrow}
        alt={"back arrow icon"}
        className={"backArrow"}
        onClick={() => {
          ctxPage.loginPageChanger(false);
          ctxPage.signUpStatusChanger(false);
        }}
      />
      <div className={"textHolder"}>
        <h1>Welcome back 👋</h1>
        <div>Happy to see you again!</div>
      </div>
      <form
        onSubmit={signInWithEmail}
        className={"loginForm"}
        autoComplete="on"
      >
        {/* <div
          className={`${classes.control} ${
            emailIsValid === false ? classes.invalid : ""
          }`}
        > */}
        <input
          type="email"
          id="email"
          value={enteredEmail}
          onChange={emailChangeHandler}
          // autocomplete={"true"}
          placeholder={"Email"}
          className={"inputField"}
        />

        <label
          className={
            // `${classes.control} ${passwordIsValid === false ? "invalid" : ""}`,
            "inputFieldPasswordLabel"
          }
        >
          <input
            type={passwordText}
            id="password"
            value={enteredPassword}
            onChange={passwordChangeHandler}
            // autocomplete={"on"}
            placeholder={"Password"}
            className={"inputFieldPassword"}
          />
          <img
            id={"togglePassword"}
            src={eyeToggle}
            alt={"password Toggle eye"}
            onClick={eyeToggleHandler}
          />
        </label>
        <div
          onClick={() => {
            ctxPage.resetPasswordStatusChanger(true);
          }}
          className={"forgotPassword"}
        >
          Forgot your password?
        </div>
        <div className={"signInButtonHolder"}>
          <Button type="submit" disabled={!formIsValid}>
            Sign In
          </Button>
          {loginFailed}
        </div>
      </form>
      <div className={"bottomElements"}>
        <div className={"orLine"}>
          <span className={"orText"}>or</span>
        </div>
        <div className={"googleLogin"}>
          <GoogleButton />
          <TwitterButton />
        </div>
        <div className={"noAccountQuestion"}>
          Don't have an account?
          <span
            onClick={() => {
              ctxPage.signUpStatusChanger(true);
            }}
            className={"register"}
          >
            {" "}
            Register{" "}
          </span>{" "}
          now
        </div>
      </div>
    </m.div>
  );
};

export default Login;
