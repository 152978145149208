import { useContext, useEffect, useState } from "react";
import PageContext from "../../../context/pageStatusContext";
import MainButtonIntro from "./Button/MainButtonIntro";
import Transaction from "../home/History/Transaction/Transaction";
import classes from "./screen.module.css";
import { motion as m, AnimatePresence } from "framer-motion";

function ScreenTwo(props) {
  const ctxPage = useContext(PageContext);
  useEffect(() => {
    setTimeout(() => {
      ctxPage.pageTwoChanger(false);
      ctxPage.pageThreeChanger(true);
    }, 7000);
  }, []);
  const [clicked, setClicked] = useState();
  function clickHandler() {
    setClicked(true);
  }
  const signals = [
    {
      status: "closed",
      entry_price: 16696.56,
      pair: "BTC/USDT",
      take_win: 17414.51,
      stop_loss: 15978.61,
      invested: 300,
      quantity: 0.0179677,
      price_closing: 17414.51,
      return: 327,
      time_ordered: "-",
      time_closing: "23.11.2022 14:12:44",
      comments: "Take-Profit reached",
    },

    {
      status: "closed",
      entry_price: 0.03,
      pair: "CFX/USDT",
      take_win: 0.03123,
      stop_loss: 0.0288,
      invested: 300,
      quantity: 10000,
      price_closing: 0.03123,
      return: 312.3,
      time_ordered: "CFX2023012409",
      time_closing: "-",
      comments: "Take-Profit reached",
    },
    {
      status: "closed",
      entry_price: 0.936,
      pair: "BTC/USDT",
      take_win: 0.00239,
      stop_loss: 0.00219,
      invested: 100,
      quantity: 106.8,
      price_closing: 0.896,
      return: 105.68,
      time_ordered: "-",
      time_closing: "23.11.2022 14:12:44",
      comments: "Stop-Loss reached",
    },
    {
      status: "canceled",
      entry_price: 5.62,
      pair: "APE/USDT",
      take_win: 5.86,
      stop_loss: 5.28,
      invested: "-",
      quantity: "-",
      time_ordered: "APE2023012118",
      price_closing: "-",
      return: "-",
      time_closing: "23.11.2022 14:12:44",
      comments: "API expired",
    },
  ];
  return (
    <>
      <div className={`wrapper ${classes.introScreen2}`}>
        <div className={classes.h1Holder}>
          <AnimatePresence>
            <m.h1
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className={classes.screen2Text}
            >
              Track how your AI trades
            </m.h1>
          </AnimatePresence>
        </div>
        {/* <div className={classes.buttonHolder}>
          <MainButtonIntro />
        </div> */}

        <div className={classes.transactionLines}>
          <div className={classes.transactionLinesInnerBox}>
            <Transaction signals={signals[0]} visible={false} />
            <Transaction signals={signals[1]} visible={false} />
            <Transaction signals={signals[2]} visible={false} />
            <Transaction signals={signals[3]} visible={false} />
          </div>
        </div>
      </div>
      <div className={classes.overlayDiv}></div>
    </>
  );
}

export default ScreenTwo;
