import { useState } from "react";
import PasswordReset from "../../passwordReset/PasswordReset";
import AccountSettings from "./AccountPage/AccountSettings";

import ChangePassword from "./ChangePassword/ChangePassword";

function AccountPages(props) {
  const [page, setPage] = useState("");
  function pageChange(props) {
    setPage(props);
  }
  if (page === "PasswordReset") {
    return <PasswordReset />;
  } else if (page === "ChangePassword") {
    return <ChangePassword pageChanger={pageChange} />;
  } else {
    return (
      <AccountSettings
        pageChanger={props.pageChanger}
        pageInternalChanger={pageChange}
      />
    );
  }
}

export default AccountPages;
