import classes from "./License.module.css";
import wallet from "../../../UI/svgs/wallet.svg";
import backArrow from "../../../UI/svgs/backArrow.svg";
import PageContext from "../../../../context/pageStatusContext";
import { useContext, useEffect, useState } from "react";
import copy from "../../../UI/svgs/copy.svg";
import { motion as m } from "framer-motion";
function License(props) {
  const ctxPage = useContext(PageContext);
  const [copied, setCopied] = useState(false);
  const [expired, setExpired] = useState(true);
  function coppyClipBoard() {
    navigator.clipboard.writeText("0xeA68CA71aED36E4C81bDB5Ef17d9Bf72FF765186");
    setCopied(true);
  }
  const handleFocus = (event) => event.target.select();
  useEffect(() => {
    if (ctxPage.licenseDays === 0) {
      setExpired(false);
    }
  }, []);
  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Subscription</span>
        </span>
      </div>
      <div className={classes.settingsGroup}>
        <div className={classes.settingsGroupItems}>
          <span className={classes.licenseDate}>
            <img
              src={wallet}
              alt={"instructions icon"}
              className={classes.settingsItemIcon}
            />
            {expired ? (
              <span className={classes.settingsText}>
                Expiration in {ctxPage.licenseDays} days
              </span>
            ) : (
              <span className={classes.settingsText}>Not active</span>
            )}
          </span>
        </div>
        <div className={classes.walletHolder}>
          <span style={{ textAlign: "center" }}>
            Activate your subscription by transferring USDT from your{" "}
            <a
              rel="noreferrer"
              href={
                "https://www.binance.com/en/support/faq/how-do-i-deposit-withdraw-cryptocurrency-on-binance-85a1c394ac1d489fb0bfac0ef2fceafd"
              }
              className={classes.binanceLink}
              target="_blank"
            >
              Binance Account
            </a>{" "}
            to the following wallet. Make sure to use the Ethereum Network or
            the BNB Smart Chain Network.
          </span>

          <form className={classes.walletNumberLine} onClick={coppyClipBoard}>
            <input
              value={"0xeA68CA71aED36E4C81bDB5Ef17d9Bf72FF765186"}
              readOnly
              type={"text"}
              onClick={handleFocus}
              className={classes.walletNumber}
            />
            <img src={copy} alt={"copy icon"} />
          </form>
          {copied && <span className={classes.copied}>copied</span>}
          <span style={{ alignSelf: "center" }}>1 USDT = 1 Day</span>
        </div>
      </div>
    </m.div>
  );
}
export default License;
