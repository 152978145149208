// This Component Manages which element should be visible on the summary part

import { useContext } from "react";
import AuthContext from "../../../../../context/auth-context";
import APIChecking from "./APIChecking/APIChecking";
import APIExpired from "./APIExpired/APIExpired";
import Bonbon from "./Bonbon/Bonbon";
import Summary from "./Summary/Summary";

export default function SummaryManagement(props) {
  const ctx = useContext(AuthContext);
  if (ctx.newUserDB === "newUser") return <Bonbon />;
  if (
    localStorage.getItem("APIChangedTime") &&
    +localStorage.getItem("APIChangedTime") + 5 * 60 * 1000 > Date.now()
  ) {
    return <APIChecking />;
  }
  if (ctx.apiKeyNotExist) {
    return <APIExpired />;
  }
  return <Summary className={`${props.summaryClass} statusTour`} />;
}
