import { useState } from "react";
import classes from "./Filter.module.css";

export default function Filter(props) {
  const options = ["All", "Open", "Closed", "Missed"];
  const [selected, setSelected] = useState(options[0]);

  const handleClick = (option) => {
    props.onClick(option);
    setSelected(option);
  };
  return (
    <div className={classes.filterLine}>
      {options.map((option) => (
        <div
          key={option}
          className={`${classes.filterOption} ${
            option === selected ? classes.filterOptionSelected : ""
          }`}
        >
          <div onClick={() => handleClick(option)}>{option}</div>
          <span
            className={`${
              option === selected ? classes.filterOptionSelectedLine : ""
            }`}
          >
            {" "}
          </span>
        </div>
      ))}
    </div>
  );
}

// const handleClick = (option) => {
//   option === "Missed" &&
//     ctxPage.clickedStateChanger({
//       won: false,
//       loss: false,
//       notInvested: true,
//       open: false,
//     });

//   option === "Closed" &&
//     ctxPage.clickedStateChanger({
//       won: true,
//       loss: true,
//       notInvested: false,
//       open: false,
//     });

//   option === "Open" &&
//     ctxPage.clickedStateChanger({
//       won: false,
//       loss: false,
//       notInvested: false,
//       open: true,
//     });
//   option === "All" &&
//     ctxPage.clickedStateChanger({
//       won: true,
//       loss: true,
//       notInvested: true,
//       open: true,
//     });

//   setSelected(option);
// };
