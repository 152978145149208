import Button from "../../../../UI/button/Button";
import { version } from "../../../../../utility/settings";

import classes from "./AdjustmentWindow.module.css";
import { motion as m } from "framer-motion";
import Range from "../RangeBar/Range";
import { useContext, useState } from "react";
import AuthContext from "../../../../../context/auth-context";
function AdjustmentWindow(props) {
  const ctx = useContext(AuthContext);
  const [volume, setVolume] = useState(ctx.maxInvest);
  function applyButtonClicked() {
    props.OnClickCloseModal();
    ctx.maxInvestChanger(volume);
    fetch(
      `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          maxInvest: volume,
        }),
      }
    );
  }

  function changeVolume(value) {
    setVolume(value);
  }

  return (
    <m.div
      className={classes.statuswindow}
      key={"statusWindowClose"}
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      exit={{ y: "170%", opacity: 0, transition: { duration: 0.2 } }}
    >
      <div className={classes.statusHolder}>
        <div className={classes.statusTitleLine}>
          <span className={classes.statusTitle}>Adjustments</span>
          {/* <span className={classes.reset}>
            Reset all
          </span> */}
        </div>
        <div className={classes.statusLine}>
          <Range changeVolume={changeVolume} />
        </div>
      </div>
      <div className={classes.statusApplyCancel}>
        <Button className={`${classes.button}`} onClick={applyButtonClicked}>
          Apply
        </Button>
        <Button
          className={`${classes.cancelButton} ${classes.button}`}
          onClick={props.OnClickCloseModal}
        >
          Cancel
        </Button>
      </div>
    </m.div>
  );
}
export default AdjustmentWindow;
