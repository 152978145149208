import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/button/Button";
import verifiedIcon from "../../../UI/svgIcons/confirm-icon.svg"; // didn't have time to change -> next time
import canceled from "../../../UI/svgIcons/cancel-icon.svg";
import classes from "../settings.module.css";
import AuthContext from "../../../../context/auth-context";
import PageContext from "../../../../context/pageStatusContext";
import backArrow from "../../../UI/svgs/backArrow.svg";
import { motion as m } from "framer-motion";
import { version } from "../../../../utility/settings";
const SettingsAPI = (props) => {
  const [key, setKey] = useState();
  const [sKey, setSKey] = useState();
  const [userValidated, setUserValidation] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("text");
  const [errorMessage, setErrorMessage] = useState(<span></span>);
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);

  useEffect(() => {
    async function res() {
      fetch(
        `${version}/buttonOnOff/${ctx.userId}.json/?auth=${ctx.sessionToken}`
      )
        .then((response) => {
          const res = response.json();
          return res;
        })
        .then((res) => {
          setKey(res.APIkey);
          setSKey(res.secretKey);
          setUserValidation(true);
        })
        .catch((Error) => console.log(Error));
    }
    res();
  }, []);

  // 0 for no state, 1 for true, 2 for false

  const verified = [
    <span id="span1"></span>,
    <img
      src={verifiedIcon}
      alt="verified"
      className={classes.verification}
      id="img1"
    />,
    <img
      src={canceled}
      alt="canceled"
      className={classes.verification}
      id="img2"
    />,
  ];

  async function checkBinance(event) {
    event.preventDefault();

    /////////////////////////////////////////////////
    // 0 for binance API 1 for Binance Secure Key //
    if (
      event.target[0].value.length > 54 &&
      event.target[1].value.length > 54
    ) {
      fetch(
        `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`
      )
        .then((res) => res.json())
        .then
        //TODO take ref link from newUser and save as a variable then post on bottonOnOff, so everytime you change api it will take ref link from newuser, problem will be with adding new promotion code
        ();
      const userCreationDataButton = {
        APIkey: event.target[0].value,
        buttonOnOff: ctx.checkedStatus,
        maxUsdt: ctx.maxUSDT,
        secretKey: event.target[1].value,
        userId: ctx.userId,
        onBoarded: true,
        maxInvest: ctx.maxInvest,
        refLink: {
          signUp: ctx.refLink,
        },
        wallet: ctx.wallet,
      };
      fetch(
        `${version}/buttonOnOff/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
        {
          method: "PATCH",
          body: JSON.stringify(userCreationDataButton),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((resp) => {
          localStorage.setItem("APIChangedTime", Date.now());
          setUserValidation(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        })
        .catch(() => {
          console.clear(); // no idea how to hide errors
        });
    } else {
      setErrorMessage(
        <span style={{ color: "red" }}>Please provide a valid API Key.</span>
      );
    }
  }
  // throw new Error("Yupii");////////
  function apiChanger() {
    setPlaceHolder("password");
    setUserValidation(false);
  }
  //////////////////////////////////////
  return (
    <m.div
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
      key={props.key}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Binance API</span>
        </span>
      </div>
      <div>
        <div className={"textHolder"}>
          <h1 style={{ textAlign: "center" }}>
            Connect your Binance account with CallBot
          </h1>
          <div
            style={{
              marginTop: "5%",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Copy and paste your{" "}
            <a
              href="https://www.binance.com/en-NZ/support/faq/how-to-create-api-360002502072"
              target={"_blank"}
              rel="noreferrer"
            >
              Binance API
            </a>{" "}
            here. The verification can take a couple of minutes.
          </div>
        </div>
        <form onSubmit={checkBinance} className={"loginForm"}>
          <input
            type={placeHolder}
            defaultValue={key}
            disabled={userValidated}
            placeholder={"Binance API"}
            className={"inputField"}
          />
          <input
            type={placeHolder}
            defaultValue={sKey}
            disabled={userValidated}
            placeholder={"Binance Secure Key"}
            className={"inputField"}
          />
          <div className={classes.buttonHolder}>
            {userValidated ? (
              <span onClick={apiChanger} className={classes.changeAPI}>
                Modify API keys
              </span>
            ) : (
              <Button type="submit" id="root" className={classes.btn}>
                Confirm
              </Button>
            )}
          </div>
          {errorMessage}
        </form>
      </div>
    </m.div>
  );
};

export default SettingsAPI;
