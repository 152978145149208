import React, { useState, useRef, createContext, useContext } from "react";
import Button from "../../UI/button/Button";
import backArrow from "../../UI/svgs/backArrow.svg";
import Card from "../../UI/card/Card";
import ThankYou from "./ThankYou";
import classes from "./passwordReset.module.css";
import PageContext from "../../../context/pageStatusContext";
import { motion as m, AnimatePresence } from "framer-motion";
const PasswordReset = (props) => {
  const ctxPage = useContext(PageContext);
  const [verificationSent, setVerificationSent] = useState(false);
  const [falseEmail, setFalseEmail] = useState(false);
  const resetEmail = useRef(null);
  const emailResender = async (event) => {
    event.preventDefault();
    const request = {
      requestType: "PASSWORD_RESET",
      email: resetEmail.current.value,
    };
    // here should be firebase API connected, take email out of form and send to the firebase
    const response = await fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    if (response.ok) {
      setVerificationSent(true);
      const data = await response.json;
    } else {
      console.error("Error");
      setFalseEmail(true);
    }
  };

  return (
    <React.Fragment>
      {!verificationSent && (
        <m.div className={`pageHolder wrapper`} {...ctxPage.animation}>
          <img
            src={backArrow}
            alt={"back arrow icon"}
            className={"backArrow"}
            onClick={() => {
              ctxPage.resetPasswordStatusChanger(false);
            }}
          />
          <div className={"textHolder"}>
            <h1>Reset password</h1>
            <div>
              It’s okay if you forget your password. Just type your email, and
              we will send a link to reset it!
            </div>
          </div>
          <form onSubmit={emailResender} className={"loginForm"}>
            <input
              type="email"
              id="email"
              ref={resetEmail}
              placeholder={"Email"}
              className={"inputField"}
            />
            <div className={classes.btn}>
              {falseEmail && (
                <span
                  style={{
                    color: "red",
                    "font-size": "13px",
                    alignSelf: "flex-start",
                  }}
                >
                  Email not exist
                </span>
              )}
              <Button type="submit">Reset</Button>
            </div>
          </form>
        </m.div>
      )}

      {verificationSent && <ThankYou />}
    </React.Fragment>
  );
};

export default PasswordReset;
