import History from "./History/History";
import ReactCSSTransitionGroup from "react-transition-group"; // ES6
import MainButton from "./ButtonCycle/MainButton";
import Summary from "./History/Summary/Summary/Summary";
import { ReactComponent as Settings } from "../../UI/svgs/settings.svg";
import { ReactComponent as Adjust } from "../../UI/svgs/adjust.svg";
import classes from "./Home.module.css";
import { Suspense, useContext, useState } from "react";
import PageContext from "../../../context/pageStatusContext";
import { motion as m, AnimatePresence } from "framer-motion";
import Draggable from "react-draggable";
import AdjustmentWindow from "./BotAdjustments/AdjustmentWindow/AdjustmentWindow";
import OverlayDiv from "../../UI/overlayDiv/OverlayDiv";
// import Range from "./Range/RangeBar/Range";

function Home() {
  const [adjustmentWindow, setAdjustmentWindow] = useState(false);
  const ctxPage = useContext(PageContext);

  function closeAdjustmentModal() {
    setAdjustmentWindow(false);
  }
  return (
    <m.div
      className={`${classes.homeHolder}   wrapper`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
    >
      <div className={classes.settingsLine}>
        <Adjust
          alt={"settings icon"}
          className={classes.settingsIcon}
          fill="#686777"
          onClick={() => {
            setAdjustmentWindow(true);
          }}
        />

        <Settings
          alt={"settings icon"}
          className={classes.settingsIcon}
          onClick={() => {
            ctxPage.settingsStatusChanger(true);
          }}
        />
      </div>
      <div className={classes.buttonHolder}>
        <MainButton />
      </div>
      <AnimatePresence>
        {adjustmentWindow && (
          <>
            <AdjustmentWindow OnClickCloseModal={closeAdjustmentModal} />{" "}
            <OverlayDiv closeModal={closeAdjustmentModal} />
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.7 }}
        >
          <History className={classes.history} />
        </m.div>
      </AnimatePresence>
    </m.div>
  );
}

export default Home;
//
