import React, { useState, useEffect, useContext } from "react";
import "../login/Login.css";
import Button from "../../UI/button/Button";
import PageContext from "../../../context/pageStatusContext";
import ThankYou from "./Thankyou/ThankYou";
import backArrow from "../../UI/svgs/backArrow.svg";
import eyeClosed from "../../UI/svgs/eyeClosed.svg";
import eyeOpen from "../../UI/svgs/eyeOpen.svg";
import { version } from "../../../utility/settings";
import GoogleButton from "../../UI/button/GoogleButton";
import { motion as m } from "framer-motion";
import TwitterButton from "../../UI/button/Twitterbutton";
import AuthContext from "../../../context/auth-context";
const SignUp = (props) => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState();
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [formIsValid, setFormIsValid] = useState(false);

  const [registered, setRegistered] = useState(false);

  const [eyeToggle, setEyeToggle] = useState(eyeClosed);
  const [passwordText, setPasswordText] = useState("password");

  const ctxPage = useContext(PageContext);
  const ctx = useContext(AuthContext);
  const currentURL = window.location.href;
  let refLinkId = "";
  if (currentURL.length > 26) {
    refLinkId = window.location.href.substring(
      window.location.href.indexOf("?rid=") + 5
    );
  }

  // console.log(queryParam.values()); // 55 test null
  useEffect(() => {
    const identifier = setTimeout(() => {
      setFormIsValid(
        enteredEmail.includes("@") && enteredPassword.trim().length > 6
      );
    }, 500);
    return () => {
      clearTimeout(identifier);
    }; // this is a cleaner function, it resets the timer on setTimeout, which helps us to delay the time of running identifier function
  }, [enteredEmail, enteredPassword]); // it will run when one of this elements changes
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const validateEmailHandler = () => {
    setEmailIsValid(enteredEmail.includes("@"));
  };

  const validatePasswordHandler = () => {
    setPasswordIsValid(enteredPassword.trim().length > 6);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const user = {
      email: enteredEmail,
      password: enteredPassword,
      returnSecureToken: true,
    };
    // replace001
    const res = await fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
      {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        const data = res.json();
        setRegistered(true);
        return data;
      })
      .then((res) => {
        const userCreationDataButton = {
          [res.localId]: {
            APIkey: "",
            buttonOnOff: false,
            maxUsdt: 100,
            secretKey: "",
            userId: res.localId,
            onBoarded: false,
            maxInvest: 10000,
            refLink: {
              signUp: refLinkId,
            },
            wallet: "",
          },
        };
        // replace001
        // AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s;
        fetch(
          `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s`,
          {
            method: "POST",
            body: JSON.stringify({
              requestType: "VERIFY_EMAIL",
              idToken: res.idToken,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        fetch(`${version}/newUser.json/?auth=${res.idToken}`, {
          method: "PATCH",
          body: JSON.stringify(userCreationDataButton),
          headers: {
            "Content-Type": "application/json",
          },
        });
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  function eyeToggleHandler() {
    if (passwordText === "password") {
      setEyeToggle(eyeOpen);
      setPasswordText("text");
    } else {
      setEyeToggle(eyeClosed);
      setPasswordText("password");
    }
  }
  return (
    <m.div className="pageHolder wrapper" {...ctxPage.animation}>
      {!registered && (
        <>
          <img
            src={backArrow}
            alt={"back arrow icon"}
            className={"backArrow"}
            onClick={() => {
              ctxPage.loginPageChanger(false);
              ctxPage.signUpStatusChanger(false);
            }}
          />
          <div className={"textHolder"}>
            <h1>Hey 👋</h1>
            <div>Let's create your account</div>
          </div>
          <form onSubmit={submitHandler} className={"loginForm"}>
            <input
              type={"email"}
              id="email"
              value={enteredEmail}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
              placeholder={"Email"}
              className={"inputField"}
            />

            <label
              className={[
                // `${classes.control} ${passwordIsValid === false ? "invalid" : ""}`,
                "inputFieldPasswordLabel",
              ]}
            >
              <input
                type={passwordText}
                id="password"
                value={enteredPassword}
                onChange={passwordChangeHandler}
                onBlur={validatePasswordHandler}
                placeholder={"Password"}
                className="inputFieldPassword"
              />
              <img
                id={"togglePassword"}
                src={eyeToggle}
                alt={"password Toggle eye"}
                onClick={eyeToggleHandler}
              />
            </label>
            <div className={"signInButtonHolder signInButtonHolderSignUp"}>
              <Button type="submit" disabled={!formIsValid}>
                Sign Up
              </Button>
            </div>
          </form>
          <div className={"bottomElements"}>
            <div className={"orLine"}>
              <span className={"orText"}>or</span>
            </div>
            <div className={"googleLogin"}>
              <GoogleButton />
              <TwitterButton />
            </div>

            <div className={"noAccountQuestion"}>
              Already have an account?
              <span
                onClick={() => {
                  ctxPage.loginPageChanger(true);
                  ctxPage.signUpStatusChanger(false);
                }}
                className={"register"}
              >
                {" "}
                Login{" "}
              </span>{" "}
              now
            </div>
          </div>

          {/* THIS IS FOR GO BACK */}
          {/* <span
            onClick={() => {
              ctxPage.signUpStatusChanger(false);
            }}
            style={{ cursor: "pointer" }}
          >
            Back
          </span> */}
        </>
      )}
      {registered && <ThankYou />}
    </m.div>
  );
};

export default SignUp;
