import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/auth-context";
import PageContext from "../../../../../../context/pageStatusContext";
import classes from "./Summary.module.css";
import { database } from "../../../../../../utility/firebase";
import * as DB from "firebase/database";
import { version } from "../../../../../../utility/settings";
function Summary(props) {
  const [profit, setProfit] = useState(0);
  const ctxPage = useContext(PageContext);
  const ctx = useContext(AuthContext);
  const [plusSign, setPlusSign] = useState(true);
  const [licenseDays, setLicenseDays] = useState(0);
  const [originalProfit, setOriginalProfit] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState({
    backgroundColor: {
      "background-color": "#FAFAFA",
    },
    color: { color: "#197CE6" },
  });

  useEffect(() => {
    let starCountRef = DB.ref(
      database,
      `userSignals/${ctx.userId}/transactions`
    );

    let recentSignals = DB.query(
      starCountRef,
      DB.orderByChild("status"),
      DB.equalTo("closed")
    );
    DB.onValue(recentSignals, (snapshot) => {
      const dataObject = Object.entries(snapshot.val()).reverse();
      const coinSumme = Math.round(
        dataObject.reduce((acc, coin) => {
          return acc + (coin[1].return - coin[1].invested);
        }, 0)
      );
      coinSumme < 0 && setPlusSign(false);

      setProfit(coinSumme);
      setOriginalProfit(coinSumme);
    });
  }, []);

  useEffect(() => {
    fetch(
      `${version}/userSignals/${ctx.userId}/general_data.json/?auth=${ctx.sessionToken}`
    )
      .then((res) => res.json())
      .then((res) => {
        const [day, month, year] = res.license_expiration
          .slice(0, -9)
          .split(".");
        const [hours, min, sec] = res.license_expiration.slice(11).split(":");
        const result = new Date(+year, +month - 1, +day, +hours, +min, +sec);
        const today = new Date();
        const licenseDaysCalc = Math.ceil(
          (result - today) / (1000 * 60 * 60 * 24)
        );
        if (isNaN(licenseDaysCalc) || licenseDaysCalc <= 0) {
          setBackgroundColor({
            backgroundColor: {
              "background-color": "#FFF0F099",
            },
            color: { color: "#E61919" },
            text: "Subscription expired",
          });
          setLicenseDays(0);
        } else {
          setLicenseDays(licenseDaysCalc);
          ctxPage.licenseDaysChanger(licenseDaysCalc);
        }
      });
  }, []);

  function goToSettings() {
    ctxPage.settingsStatusChanger(true);
  }

  return (
    <div
      className={`${classes.statusHolder} ${props.className}`}
      style={backgroundColor.backgroundColor}
    >
      <div className={`${classes.statusLine} ${classes.statusLineEarned}`}>
        <span className={classes.statusText}>Total Earned: </span>
        <span className={classes.madeCoin}>{profit} $</span>
      </div>

      <div>
        <span className={classes.statusText}>Subscription expires in: </span>
        <span
          className={classes.licenseDays}
          style={backgroundColor.color}
          onClick={() => {
            ctxPage.settingsStatusChanger(true);
          }}
        >
          {licenseDays} days
        </span>
      </div>
    </div>
  );
}
//TODO License Expired should be visible when license days 0
// TODO add the link to the license on setting
export default Summary;
