import { useContext, useState } from "react";
import PageContext from "../../../context/pageStatusContext";
import ScreenOne from "./screen1";
import ScreenTwo from "./screen2";
import ScreenThree from "./screen3";
import { motion as m, AnimatePresence } from "framer-motion";
function Onboarding() {
  const ctxPage = useContext(PageContext);
  if (!ctxPage.pageTwo && !ctxPage.pageThree) {
    return <ScreenOne />;
  } else if (ctxPage.pageTwo && !ctxPage.pageThree) {
    return <ScreenTwo />;
  } else if (ctxPage.pageThree && !ctxPage.pageTwo) {
    return <ScreenThree />;
  }
}

export default Onboarding;
