import classes from "./APIChecking.module.css";
import loading from "../../../../../UI/images/loading.gif";
export default function APIChecking() {
  return (
    <div className={classes.statusHolder}>
      <div className={classes.messageHolder}>
        <span className={classes.mainMessage}>
          Thanks! CallBot is checking your API
        </span>
        <img src={loading} className={classes.loading} />
      </div>
    </div>
  );
}
