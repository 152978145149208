import React, { useContext } from "react";
import Card from "../../UI/card/Card";
import backArrow from "../../UI/svgs/backArrow.svg";
import emailCheck from "../../UI/svgs/emailCheck.svg";
import Button from "../../UI/button/Button";
import classes from "./passwordReset.module.css";
import PageContext from "../../../context/pageStatusContext";

const ThankYou = () => {
  const ctxPage = useContext(PageContext);
  return (
    <div className={`pageHolder wrapper`}>
      <img
        src={backArrow}
        alt={"back arrow icon"}
        className={"backArrow"}
        onClick={() => {
          ctxPage.resetPasswordStatusChanger(false);
          ctxPage.loginPageChanger(true);
        }}
      />
      <img
        src={emailCheck}
        alt={"back arrow icon"}
        className={classes.emailCheck}
      />

      <div className={"textHolder textHolderCentral"}>
        <h1>Please check your Email for further steps</h1>
        <div>We have sent a password recover instructions to your email.</div>
      </div>
      <div className={`signInButtonHolder ${classes.button}`}>
        <Button
          onClick={() => {
            ctxPage.resetPasswordStatusChanger(false);
            ctxPage.loginPageChanger(true);
          }}
        >
          To Login
        </Button>
      </div>
    </div>
  );
};

export default ThankYou;
