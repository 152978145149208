import { useContext } from "react";
import AuthContext from "../../../../../context/auth-context";
import PageContext from "../../../../../context/pageStatusContext";
import Button from "../../../../UI/button/Button";
import ibot from "../../../../UI/images/iBotReset.png";
import ibotShade from "../../../../UI/images/iBotShade.png";
import classes from "./Confirmation.module.css";

function Confirmation() {
  const ctxPage = useContext(PageContext);
  function logout() {
    ctxPage.settingsStatusChanger(false);
  }
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
          <img
            src={ibotShade}
            className={classes.iBotShade}
            alt={"welcoming bot shade"}
          />
        </div>
        <div className={classes.textHolder}>
          <h1>Your message on the way</h1>
          <div className={classes.introText}>
            We will reply within couple of days
          </div>
        </div>
        <div className={classes.buttonsHolder}>
          <Button onClick={logout}>Home</Button>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
