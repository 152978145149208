import React from "react";
import { useState } from "react";
import ibot from "../../../UI/images/iBotReset.png";
import ibotShade from "../../../UI/images/iBotShade.png";
import classes from "./FAQ.module.css";
import backArrow from "../../../UI/svgs/backArrow.svg";
import { useContext } from "react";
import PageContext from "../../../../context/pageStatusContext";
import { motion as m, AnimatePresence } from "framer-motion";
import { getDatabase, ref, set } from "firebase/database";
import Question from "./Question";
import { questions } from "./Q&A";
function FAQ(props) {
  const ctxPage = useContext(PageContext);
  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>FAQ</span>
        </span>
      </div>

      <div className={classes.questionWrapper}>
        <section className="faq">
          {questions.map((item) => (
            <Question question={item.question} answer={item.answer} />
          ))}
        </section>
      </div>
    </m.div>
  );
}

export default FAQ;
