import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import classes from "./Range.module.css";
import AuthContext from "../../../../../context/auth-context";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#FCD535" : "#FCD535",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

export default function Range(props) {
  const ctx = React.useContext(AuthContext);

  const [volume, setVolume] = React.useState(ctx.maxInvest);
  const [value, setValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(10000);
  const [step, setStep] = React.useState(10);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    ctx.maxInvest === 1000 && setValue(0);
    ctx.maxInvest === 2000 && setValue(10);
    ctx.maxInvest === 3000 && setValue(20);
    ctx.maxInvest === 4000 && setValue(30);
    ctx.maxInvest === 5000 && setValue(40);
    ctx.maxInvest === 10000 && setValue(50);
    ctx.maxInvest === 20000 && setValue(60);
    ctx.maxInvest === 50000 && setValue(70);
    ctx.maxInvest === 80000 && setValue(80);
    ctx.maxInvest === 100000 && setValue(90);
    ctx.maxInvest === 500000 && setValue(100);
  }, []);

  React.useEffect(() => {
    value === 0 && setVolume(1000);
    value === 10 && setVolume(2000);
    value === 20 && setVolume(3000);
    value === 30 && setVolume(4000);
    value === 40 && setVolume(5000);
    value === 50 && setVolume(10000);
    value === 60 && setVolume(20000);
    value === 70 && setVolume(50000);
    value === 80 && setVolume(80000);
    value === 90 && setVolume(100000);
    value === 100 && setVolume(500000);
  }, [value]);

  const getAriaValueText = (value) => {};

  function changeVolume() {
    props.changeVolume(volume);
  }
  return (
    <>
      <div className={classes.investmentStatement}>
        <div>Max. Investment: </div> <span>{volume} </span>USDT
      </div>
      <Box sx={{ width: "90%", alignSelf: "center" }}>
        <IOSSlider
          aria-label="ios slider"
          defaultValue={value}
          value={value}
          min={0}
          max={100}
          step={null}
          marks={[
            { value: 0 },
            { value: 10 },
            { value: 20 },
            { value: 30 },
            { value: 40 },
            { value: 50 },
            { value: 60 },
            { value: 70 },
            { value: 80 },
            { value: 90 },
            { value: 100 },
          ]}
          onChange={handleChange}
          getAriaValueText={getAriaValueText}
          onChangeCommitted={changeVolume}
        />
        <Typography>{getAriaValueText(value)}</Typography>
      </Box>
    </>
  );
}
