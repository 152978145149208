import ibot from "../../../UI/images/iBotReset.png";
import ibotShade from "../../../UI/images/iBotShade.png";
import classes from "./Verified.module.css";
import success from "../../../UI/svgIcons/success.svg";
export default function Verified() {
  
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        {/* <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
          <img
            src={ibotShade}
            className={classes.iBotShade}
            alt={"welcoming bot shade"}
          />
        </div> */}
        <div className={classes.buttonsHolder}>
          <img src={success} alt={"successIcon"} className={classes.success} />
        </div>
        <div className={classes.textHolder}>
          <h1>Verified!</h1>
          <div className={classes.introText}>Let's start trading</div>
        </div>
      </div>
    </div>
  );
}
