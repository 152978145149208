import InfiniteScroll from "react-infinite-scroll-component";
import Transaction from "../Transaction/Transaction";

export default function InfiniteScrollCustom(props) {
  return (
    <InfiniteScroll
      dataLength={props.data.length}
      next={props.handleNewData}
      hasMore={true}
    >
      {props.data.length != 0 ? (
        props.data.map((item) => (
          <span key={item[0]} style={{ width: "100%" }}>
            <Transaction signals={item[1]} visible={true} />
          </span>
        ))
      ) : (
        <span style={{ marginTop: "20px", color: "#8E8D99", fontSize: "14px" }}>
          No transactions yet
        </span>
      )}
    </InfiniteScroll>
  );
}
