import classes from "./Promotion.module.css";
import walletIcon from "../../../UI/svgs/wallet.svg";
import backArrow from "../../../UI/svgs/backArrow.svg";
import PageContext from "../../../../context/pageStatusContext";
import { useContext, useEffect, useRef, useState } from "react";
import copy from "../../../UI/svgs/copy.svg";
import { motion as m, AnimatePresence } from "framer-motion";
import AuthContext from "../../../../context/auth-context";
import Button from "../../../UI/button/Button";
import { version } from "../../../../utility/settings";

function Promotion(props) {
  const ctxPage = useContext(PageContext);
  const ctx = useContext(AuthContext);
  const [placeHolder, setPlaceHolder] = useState("text");
  const [copied, setCopied] = useState(false);
  const [wallet, setWallet] = useState(ctx.wallet);
  const [modified, setModified] = useState(
    ctx.wallet.length > 5 ? true : false
  );
  const refLink = `https://callbotcrypto.xyz/?rid=${ctx.userId}`;

  useEffect(() => {
    fetch(
      `${version}${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`
    )
      .then((res) => res.json())
      .then((data) => {
        setWallet(data.wallet);
      });
  }, []);

  function walletFetcher(event) {
    event.preventDefault();

    fetch(
      `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          wallet: event.target[0].value,
        }),
      }
    );
    ctx.walletChanger(event.target[0].value);
    setModified(true);
    setWallet(event.target[0].value);
    setPlaceHolder("password");
  }

  function apiChanger() {
    setPlaceHolder("text");
    setModified(false);
  }
  function coppyClipBoard() {
    navigator.clipboard.writeText(refLink);
    setCopied(true);
  }
  const handleFocus = (event) => event.target.select();

  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Referral Program</span>
        </span>
      </div>
      <div className={classes.settingsGroup}>
        <div className={classes.settingsGroupItems}>
          <span className={classes.licenseDate}>
            <img
              src={walletIcon}
              alt={"instructions icon"}
              className={classes.settingsItemIcon}
            />

            <span className={classes.settingsText}>
              Earn 10$ for each friend invited{" "}
            </span>
          </span>
        </div>
        <div className={classes.promoCode}>
          <span className={classes.promoText}>Your Referral Link:</span>
          <form className={classes.walletNumberLine} onClick={coppyClipBoard}>
            <input
              value={refLink}
              readOnly
              type={"text"}
              onClick={handleFocus}
              className={classes.walletNumber}
            />
            <img src={copy} alt={"copy icon"} />
          </form>
          <span className={`${copied ? classes.copied : classes.uncopied}`}>
            copied
          </span>
        </div>

        <div className={classes.walletHolder}>
          <span className={classes.promoText}>Your receiving wallet:</span>

          <form className={"loginForm"} onSubmit={walletFetcher}>
            <input
              onClick={handleFocus}
              className={"inputField"}
              type={placeHolder}
              disabled={modified}
              defaultValue={wallet}
            />
            {modified ? (
              <span onClick={apiChanger} className={classes.changeWallet}>
                Modify wallet
              </span>
            ) : (
              <Button type="submit" id="root" className={classes.btn}>
                Confirm
              </Button>
            )}
          </form>
        </div>
      </div>
    </m.div>
  );
}
export default Promotion;
