import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
  useCircularInputContext,
} from "react-circular-input";
import React, { useEffect, useState, useContext } from "react";
import classes from "./MainButton.module.css";
import Button from "../../home/ButtonCycle/Button";
import PageContext from "../../../../context/pageStatusContext";
import PatternSurround from "../../home/ButtonCycle/PatternSurround";
function MainButtonIntro(props) {
  const ctxPage = useContext(PageContext);
  const [value, setValue] = useState(0);

  function setOnChangeEndValue(value) {
    setTimeout(() => {
      ctxPage.pageTwoChanger(true);
    }, 2000);
  }

  const min = 0;
  const max = 0.98;

  // get value within limits
  const valueWithinLimits = (v) => Math.min(Math.max(v, min), max);

  return (
    <div className={`${classes.buttonWrapper} buttonTour`}>
      <div className={classes.buttonHolder}>
        <div className={classes.circle}>
          <CircularInput
            value={valueWithinLimits(value)}
            onChange={(v) => setValue(valueWithinLimits(v))}
            onChangeEnd={setOnChangeEndValue}
          >
            {!props.invisible && (
              <circle
                stroke="#fafafa"
                strokeWidth="25"
                fill="none"
                cx="100"
                cy="100"
                r="125"
                className={classes.mainCircle}
              ></circle>
            )}
            (<circle fill="white" cx="100" cy="100" r="100"></circle>
            )
            <PatternSurround />
            {!props.invisible && <CircularTrack className={classes.mn_input} />}
            {!props.invisible && (
              <CircularProgress className={classes.pr_input} />
            )}
            {/* <line x1={100} x2={100} y1={16} y2={-30} stroke="#B4B3C0" /> */}
            {!props.invisible && (
              <>
                <CircularThumb
                  r={24}
                  fill="rgba(255,255,255,0.5)"
                  className={`${classes.input_thumb} maxUsdtTour`}
                />
                <CustomComponent />
              </>
            )}
            <circle
              xmlns="http://www.w3.org/2000/svg"
              cx="100"
              cy="100"
              r="50"
              fill="#E9E9E9"
            />
            <circle
              xmlns="http://www.w3.org/2000/svg"
              cx="100"
              cy="100"
              r="18.1034"
              fill="#E9E9E9"
            />
          </CircularInput>
        </div>
        <div className={`${classes.buttonCtrl} ${classes.highlightingButton}`}>
          <Button onClick={props.click} />
        </div>
      </div>
    </div>
  );
}

const CustomComponent = () => {
  const [xAxis, setxAxis] = useState(11);
  const { getPointFromValue, value } = useCircularInputContext();
  const { x, y } = getPointFromValue();

  // this useEffect needs to corret the position of text inside the circle
  // this useEffect needs to corret the position of text inside the circle
  useEffect(() => {
    if (value < 0.01) {
      setxAxis(4);
    } else if (value > 0.37) {
      setxAxis(14);
    } else {
      setxAxis(11);
    }
  }, [value]);

  return (
    <text
      x={x - xAxis}
      y={y + 3}
      style={{ pointerEvents: "none" }}
      className={classes.thumb_text}
    >
      {Math.round(value * 51) * 100}
    </text>
  );
};

export default MainButtonIntro;
