const liveVersion =
  "https://callbottest-d1644-default-rtdb.europe-west1.firebasedatabase.app/";
const testVersion =
  "https://callbot-78a18-default-rtdb.europe-west1.firebasedatabase.app";

export const version = liveVersion;
// export default version;

export async function signInfunc(idToken, localId, ctx, ctxPage) {
  const response = await fetch(
    `${version}/buttonOnOff/${localId}.json/?auth=${idToken}`
  );
  if (!response.ok) {
    return console.log("Error");
  }
  const data = await response.json();

  if (data === null) {
    const responseNewUser = await fetch(
      `${version}/newUser/${localId}.json/?auth=${idToken}`
    );
    if (!response.ok) {
      return console.log("Error");
    }
    const dataNewUser = await responseNewUser.json();
    preConfig(dataNewUser, ctx, idToken, localId, ctxPage);
  } else {
    ctx.newUserDBChanger("buttonOnOff");
    ctx.branchChanger(localId);
    preConfig(data, ctx, idToken, localId, ctxPage);
  }
  fetch(`${version}/userSignals/${localId}.json/?auth=${idToken}`)
    .then((res) => {
      const data = res.json();
      return data;
    })
    .then(
      (res) =>
        res != null && ctx.apiKeyNotExistChanger(!res.general_data.api_correct)
    )
    .catch((err) => console.log(err));
}

function preConfig(data, ctx, idToken, localId, ctxPage) {
  data.buttonOnOff
    ? ctx.checkStatusHandler(true)
    : ctx.checkStatusHandler(false);
  if (data.APIkey === "" || data.secretKey === "") {
    ctx.apiKeyNotExistChanger(true);
  }
  ctx.userValidation(true);
  ctx.maxUSDTChanger(data.maxUsdt);
  ctx.walletChanger(data.wallet);
  ctx.refLinkChanger(data.refLink.signUp);
  ctx.userIdChanger(localId);
  ctx.maxInvestChanger(data.maxInvest);
  if (!data.onBoarded) {
    ctxPage.onBoardingChanger(true);
  } else {
    ctx.loginHandler(true);
  }
  ctx.sessionTokenChanger(idToken);
}
