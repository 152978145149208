import * as DB from "firebase/database";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../context/auth-context";
import { database } from "../../../../../utility/firebase";
import InfiniteScrollCustom from "./InfiniteScrollCustom";

let dataAmount = 80;
export default function HistoryNewAll(props) {
  const [data, setData] = useState([]);

  const ctx = useContext(AuthContext);
  let starCountRef = DB.ref(database, `userSignals/${ctx.branch}/transactions`);

  useEffect(() => {
    let recentSignals = DB.query(
      starCountRef,
      DB.orderByKey(),
      DB.limitToLast(dataAmount)
    );
    DB.onValue(recentSignals, (snapshot) => {
      const dataObject = Object.entries(snapshot.val()).reverse();
      setData(dataObject);
    });
  }, []);

  const handleNewData = (filter) => {
    dataAmount += 50;
    let newSignals = DB.query(
      starCountRef,
      DB.orderByKey(),
      DB.limitToLast(dataAmount)
    );
    DB.onValue(newSignals, (snapshot) => {
      const dataObject = Object.entries(snapshot.val()).reverse();
      setData(dataObject);
    });
  };

  return <InfiniteScrollCustom data={data} handleNewData={handleNewData} />;
}
