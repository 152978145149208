export default function PatternSurround() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(32,32) ">
      <path d="M69.5875 0H68.9043V4.66627H69.5875V0Z" fill="#686777" />
      <path
        d="M62.3729 0.312375L61.6934 0.383789L62.1811 5.0245L62.8606 4.95308L62.3729 0.312375Z"
        fill="#686777"
      />
      <path
        d="M55.2301 1.39213L54.5618 1.53418L55.532 6.09848L56.2003 5.95644L55.2301 1.39213Z"
        fill="#686777"
      />
      <path
        d="M48.2377 3.19159L47.5879 3.40271L49.0299 7.8406L49.6797 7.62948L48.2377 3.19159Z"
        fill="#686777"
      />
      <path
        d="M41.4728 5.72712L40.8486 6.005L42.7467 10.2679L43.3708 9.98997L41.4728 5.72712Z"
        fill="#686777"
      />
      <path
        d="M35.0023 8.94919L34.4106 9.29089L36.7446 13.3316L37.3362 12.9899L35.0023 8.94919Z"
        fill="#686777"
      />
      <path
        d="M28.9733 12.7803L28.4199 13.1833L31.1732 16.963L31.7266 16.5599L28.9733 12.7803Z"
        fill="#686777"
      />
      <path
        d="M23.2638 17.3219L22.7561 17.7792L25.8792 21.2463L26.3869 20.7891L23.2638 17.3219Z"
        fill="#686777"
      />
      <path
        d="M18.1177 22.3908L17.6606 22.8986L21.1291 26.0203L21.5862 25.5125L18.1177 22.3908Z"
        fill="#686777"
      />
      <path
        d="M13.5838 28.0134L13.1807 28.5668L16.9605 31.3199L17.3636 30.7665L13.5838 28.0134Z"
        fill="#686777"
      />
      <path
        d="M9.53902 33.9903L9.19751 34.582L13.2392 36.9145L13.5807 36.3227L9.53902 33.9903Z"
        fill="#686777"
      />
      <path
        d="M6.20246 40.3994L5.92456 41.0236L10.1876 42.9215L10.4655 42.2974L6.20246 40.3994Z"
        fill="#686777"
      />
      <path
        d="M3.55708 47.1201L3.34595 47.7699L7.78406 49.2119L7.99519 48.5621L3.55708 47.1201Z"
        fill="#686777"
      />
      <path
        d="M1.63375 54.0757L1.4917 54.744L6.05622 55.7142L6.19828 55.0459L1.63375 54.0757Z"
        fill="#686777"
      />
      <path
        d="M0.439093 61.2016L0.367676 61.8811L5.00862 62.3689L5.08003 61.6894L0.439093 61.2016Z"
        fill="#686777"
      />
      <path d="M4.6665 68.4091H0V69.0923H4.6665V68.4091Z" fill="#686777" />
      <path
        d="M4.95515 75.1382L0.314209 75.626L0.385627 76.3054L5.02656 75.8177L4.95515 75.1382Z"
        fill="#686777"
      />
      <path
        d="M5.95418 81.7997L1.38965 82.7699L1.5317 83.4382L6.09623 82.468L5.95418 81.7997Z"
        fill="#686777"
      />
      <path
        d="M7.631 88.3215L3.19214 89.7611L3.40293 90.411L7.84179 88.9713L7.631 88.3215Z"
        fill="#686777"
      />
      <path
        d="M9.99648 94.628L5.73242 96.5237L6.00999 97.148L10.274 95.2523L9.99648 94.628Z"
        fill="#686777"
      />
      <path
        d="M12.9914 100.656L8.95093 102.991L9.29275 103.582L13.3332 101.248L12.9914 100.656Z"
        fill="#686777"
      />
      <path
        d="M16.6139 106.335L12.8396 109.079L13.2414 109.631L17.0157 106.887L16.6139 106.335Z"
        fill="#686777"
      />
      <path
        d="M20.8004 111.609L17.3303 114.729L17.7871 115.237L21.2572 112.117L20.8004 111.609Z"
        fill="#686777"
      />
      <path
        d="M25.5177 116.419L22.3928 119.885L22.9002 120.342L26.0252 116.877L25.5177 116.419Z"
        fill="#686777"
      />
      <path
        d="M30.7069 120.704L27.9626 124.478L28.5153 124.88L31.2595 121.105L30.7069 120.704Z"
        fill="#686777"
      />
      <path
        d="M36.3286 124.409L33.9961 128.451L34.5879 128.792L36.9204 124.751L36.3286 124.409Z"
        fill="#686777"
      />
      <path
        d="M42.3018 127.526L40.4053 131.79L41.0295 132.067L42.9261 127.804L42.3018 127.526Z"
        fill="#686777"
      />
      <path
        d="M48.5569 129.99L47.1172 134.428L47.7671 134.639L49.2068 130.2L48.5569 129.99Z"
        fill="#686777"
      />
      <path
        d="M55.0551 131.811L54.0801 136.374L54.7482 136.517L55.7232 131.954L55.0551 131.811Z"
        fill="#686777"
      />
      <path
        d="M61.6989 132.92L61.2063 137.56L61.8857 137.632L62.3784 132.992L61.6989 132.92Z"
        fill="#686777"
      />
      <path
        d="M69.0956 133.327H68.4124V137.993H69.0956V133.327Z"
        fill="#686777"
      />
      <path
        d="M75.8202 132.974L75.1406 133.045L75.626 137.686L76.3055 137.615L75.8202 132.974Z"
        fill="#686777"
      />
      <path
        d="M82.4713 131.905L81.803 132.047L82.7716 136.612L83.44 136.47L82.4713 131.905Z"
        fill="#686777"
      />
      <path
        d="M88.9736 130.153L88.3237 130.364L89.765 134.803L90.4148 134.592L88.9736 130.153Z"
        fill="#686777"
      />
      <path
        d="M95.2607 127.712L94.6365 127.99L96.5338 132.253L97.158 131.976L95.2607 127.712Z"
        fill="#686777"
      />
      <path
        d="M101.248 124.667L100.656 125.008L102.988 129.05L103.58 128.708L101.248 124.667Z"
        fill="#686777"
      />
      <path
        d="M106.9 120.975L106.347 121.376L109.088 125.152L109.641 124.751L106.9 120.975Z"
        fill="#686777"
      />
      <path
        d="M112.122 116.739L111.614 117.196L114.734 120.666L115.242 120.209L112.122 116.739Z"
        fill="#686777"
      />
      <path
        d="M116.882 111.977L116.425 112.484L119.891 115.609L120.349 115.101L116.882 111.977Z"
        fill="#686777"
      />
      <path
        d="M121.107 106.734L120.705 107.286L124.48 110.03L124.881 109.478L121.107 106.734Z"
        fill="#686777"
      />
      <path
        d="M124.776 101.087L124.434 101.678L128.474 104.014L128.816 103.422L124.776 101.087Z"
        fill="#686777"
      />
      <path
        d="M127.809 95.0656L127.531 95.6897L131.794 97.5884L132.072 96.9643L127.809 95.0656Z"
        fill="#686777"
      />
      <path
        d="M130.223 88.7911L130.012 89.4408L134.449 90.8843L134.661 90.2346L130.223 88.7911Z"
        fill="#686777"
      />
      <path
        d="M131.956 82.282L131.814 82.9502L136.378 83.922L136.521 83.2537L131.956 82.282Z"
        fill="#686777"
      />
      <path
        d="M133 75.6338L132.928 76.3132L137.569 76.8026L137.64 76.1232L133 75.6338Z"
        fill="#686777"
      />
      <path
        d="M138.007 68.9009H133.34V69.5841H138.007V68.9009Z"
        fill="#686777"
      />
      <path
        d="M137.617 61.7012L132.975 62.1841L133.046 62.8636L137.687 62.3807L137.617 61.7012Z"
        fill="#686777"
      />
      <path
        d="M136.477 54.577L131.912 55.5424L132.053 56.2108L136.619 55.2454L136.477 54.577Z"
        fill="#686777"
      />
      <path
        d="M134.505 47.3369L130.072 48.7834L130.284 49.4325L134.717 47.9859L134.505 47.3369Z"
        fill="#686777"
      />
      <path
        d="M131.997 40.8453L127.733 42.7402L128.01 43.3646L132.275 41.4696L131.997 40.8453Z"
        fill="#686777"
      />
      <path
        d="M128.708 34.4266L124.667 36.759L125.008 37.3508L129.05 35.0183L128.708 34.4266Z"
        fill="#686777"
      />
      <path
        d="M124.768 28.3543L120.992 31.0964L121.394 31.6492L125.169 28.9071L124.768 28.3543Z"
        fill="#686777"
      />
      <path
        d="M120.215 22.7519L116.745 25.8712L117.201 26.3793L120.672 23.26L120.215 22.7519Z"
        fill="#686777"
      />
      <path
        d="M115.112 17.6615L111.987 21.1276L112.495 21.585L115.619 18.1189L115.112 17.6615Z"
        fill="#686777"
      />
      <path
        d="M109.479 13.1232L106.735 16.8973L107.287 17.2991L110.032 13.525L109.479 13.1232Z"
        fill="#686777"
      />
      <path
        d="M103.416 9.19115L101.082 13.2314L101.673 13.5733L104.008 9.53296L103.416 9.19115Z"
        fill="#686777"
      />
      <path
        d="M96.9776 5.92706L95.0789 10.1896L95.703 10.4676L97.6018 6.20505L96.9776 5.92706Z"
        fill="#686777"
      />
      <path
        d="M90.2354 3.34669L88.791 7.78381L89.4407 7.99527L90.8851 3.55815L90.2354 3.34669Z"
        fill="#686777"
      />
      <path
        d="M83.2505 1.48062L82.2803 6.04492L82.9486 6.18697L83.9188 1.62266L83.2505 1.48062Z"
        fill="#686777"
      />
      <path
        d="M76.1177 0.372108L75.6299 5.01282L76.3094 5.08423L76.7972 0.443522L76.1177 0.372108Z"
        fill="#686777"
      />
    </g>
  );
}
