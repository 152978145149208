import { version } from "../../../../utility/settings";
import classes from "./MainButton.module.css";
import { useCallback, useContext, useState, useEffect } from "react";
import AuthContext from "../../../../context/auth-context";
//TODO Bug in Button for onboarding
function Button(props) {
  const ctx = useContext(AuthContext);
  const [toggle, setToggle] = useState(ctx.checkedStatus);
  const [color, setColor] = useState(ctx.checkedStatus ? "#FCD535" : "#E9E9E9");
  useEffect(() => {
    setToggle(ctx.checkedStatus);
  }, [ctx.checkedStatus]);

  const buttonSwitcher = useCallback(() => {
    props.onClick();
    if (toggle) {
      // turn off button if it was active
      setColor("#E9E9E9");
      fetch(
        `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            buttonOnOff: false,
          }),
        }
      );
      setToggle(false);
      ctx.checkStatusHandler(false);
    } else {
      setColor("#FCD535");
      fetch(
        `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            buttonOnOff: true,
          }),
        }
      );
      setToggle(true);
      ctx.checkStatusHandler(true);
    }
  }, [toggle]);

  return (
    <div
      className={`${classes.button} ${classes.ios}`}
      onClick={buttonSwitcher}
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.ios}
      >
        <circle cx="50" cy="50" r="50" fill={color} />
        <circle cx="49.9999" cy="50.0001" r="18.1034" fill={color} />
        <path
          d="M64.1638 38.0632C66.9663 40.8666 68.8745 44.4379 69.6474 48.3257C70.4202 52.2135 70.0229 56.2432 68.5057 59.9052C66.9885 63.5672 64.4195 66.6971 61.1236 68.8992C57.8277 71.1013 53.9528 72.2766 49.9889 72.2766C46.025 72.2766 42.1502 71.1013 38.8542 68.8992C35.5583 66.6971 32.9893 63.5672 31.4721 59.9052C29.9549 56.2432 29.5576 52.2135 30.3305 48.3257C31.1033 44.4379 33.0116 40.8666 35.814 38.0632"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 27.7299V50"
          stroke="white"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default Button;
