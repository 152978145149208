import React from "react";
import { useState } from "react";
import classes from "./FAQ.module.css";

function Question(props) {
  const [isActive, setActive] = useState(false);
  const handleClick = (id) => {
    setActive(!isActive);
  };
  return (
    <div className="question-wrapper">
      <div
        className={classes.question}
        id={props.id}
        onClick={() => handleClick(props.id)}
      >
        <h3>{props.question}</h3>
        <button>
          <div className={isActive ? classes.activeBtn : ""}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M1.54571 7.56445H6.14113V12.1599C6.14113 12.627 6.52534 13.0187 6.99995 13.0187C7.47456 13.0187 7.85876 12.627 7.85876 12.1599V7.56445H12.4542C12.9213 7.56445 13.313 7.18025 13.313 6.70564C13.313 6.23103 12.9213 5.84682 12.4542 5.84682H7.85876V1.2514C7.85876 0.784319 7.47456 0.392578 6.99995 0.392578C6.52534 0.392578 6.14113 0.784319 6.14113 1.2514V5.84682H1.54571C1.07863 5.84682 0.68689 6.23103 0.68689 6.70564C0.68689 7.18025 1.07863 7.56445 1.54571 7.56445Z"
                fill="#686777"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        className={`${isActive ? classes.active : ""} ${classes.answer}`}
        dangerouslySetInnerHTML={{ __html: props.answer }}
      ></div>
    </div>
  );
}
export default Question;
