import { createContext, useState } from "react";

const PageContext = createContext({
  madeCoin: 0,
  madeCoinChanger: () => {},
  licenseDays: 0,
  licenseDaysChanger: () => {},
  isFiltered: false,
  filterStatusChanger: () => {},
  settingsStatus: false,
  settingsStatusChanger: () => {},
  signUpStatus: false,
  signUpStatusChanger: () => {},
  resetPasswordStatus: false,
  resetPasswordStatusChanger: () => {},
  transactionsDataChanger: () => {},
  transactionsData: [],
  maxUSDT: 0,
  maxUSDTChanger: () => {},
  pageTwo: false,
  pageTwoChanger: () => {},
  pageThree: false,
  pageThreeChanger: () => {},
  onBoarding: false,
  onBoardingChanger: () => {},
  loginPage: false,
  loginPageChanger: () => {},
  animation: {
    initial: { x: "100%" },
    animate: { x: "0%" },
    transition: { duration: 0.5, ease: "easeOut" },
    exit: { opacity: 1 },
  },
  clickedState: {},
  clickedStateChanger: () => {},
  animationMainPage: {},
  openSignalsAmount: 0,
  openSignalsAMountChanger: () => {},
});

export function PageProvider({ children }) {
  const [madeCoin, setMadeCoin] = useState(0);
  const [settingsStatus, setSettingsStatus] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(false);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(false);
  const [transactionsData, setTransactionsData] = useState([]);
  const [licenseDays, setLicenseDays] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [maxUSDT, setMaxUSDT] = useState(0);
  const [pageTwo, setPageTwo] = useState(false);
  const [pageThree, setPageThree] = useState(false);
  const [onBoarding, setOnBoarding] = useState();
  const [loginPage, setLoginPage] = useState(false);
  const [openSignalsAmount, setOpenSignalsAmount] = useState(0);
  const [clickedState, setClickedState] = useState({
    won: true,
    loss: true,
    notInvested: true,
    open: true,
  });

  const animationMainPage = {
    initial: { x: "100%" },
    animate: { x: "0%" },
    transition: { duration: 0.5, ease: "easeOut" },
    exit: { x: "-150%", transaction: { duration: 0.2 } },
  };
  const animation = {
    initial: { x: "100%" },
    animate: { x: "0%" },
    transition: { duration: 0.2, ease: "easeOut" },
    exit: { x: "150%", opacity: 0, transaction: { duration: 0.1 } },
  };

  ////// Function to control user logged in or not

  const madeCoinChanger = (props) => {
    setMadeCoin(props);
  };
  const settingsStatusChanger = (props) => {
    setSettingsStatus(props);
  };
  const signUpStatusChanger = (props) => {
    setSignUpStatus(props);
  };
  const resetPasswordStatusChanger = (props) => {
    setResetPasswordStatus(props);
  };
  const transactionsDataChanger = (props) => {
    setTransactionsData(props);
  };
  const licenseDaysChanger = (props) => {
    setLicenseDays(props);
  };
  const filterStatusChanger = (props) => {
    setIsFiltered(props);
  };
  const maxUSDTChanger = (props) => {
    setMaxUSDT(props);
  };
  const pageTwoChanger = (props) => {
    setPageTwo(props);
  };
  const pageThreeChanger = (props) => {
    setPageThree(props);
  };
  const onBoardingChanger = (props) => {
    setOnBoarding(props);
  };
  const loginPageChanger = (props) => {
    setLoginPage(props);
  };
  const clickedStateChanger = (props) => {
    setClickedState(props);
  };

  const openSignalsAMountChanger = (props) => {
    setOpenSignalsAmount(props);
  };

  return (
    <PageContext.Provider
      value={{
        madeCoin,
        madeCoinChanger,
        licenseDays,
        licenseDaysChanger,
        settingsStatus,
        settingsStatusChanger,
        signUpStatus,
        signUpStatusChanger,
        resetPasswordStatus,
        resetPasswordStatusChanger,
        transactionsData,
        transactionsDataChanger,
        isFiltered,
        filterStatusChanger,
        maxUSDT,
        maxUSDTChanger,
        pageTwo,
        pageTwoChanger,
        pageThree,
        pageThreeChanger,
        onBoarding,
        onBoardingChanger,
        loginPage,
        loginPageChanger,
        animation,
        animationMainPage,
        clickedState,
        clickedStateChanger,
        openSignalsAmount,
        openSignalsAMountChanger,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
export default PageContext;
