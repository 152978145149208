import React, { useContext } from "react";
import { motion as m, AnimatePresence } from "framer-motion";
import classes from "./settings.module.css";
import AuthContext from "../../../context/auth-context";
import PageContext from "../../../context/pageStatusContext";
import backArrow from "../../UI/svgs/backArrow.svg";
import binanceAPI from "../../UI/svgs/binanceAPI.svg";
import license from "../../UI/svgs/license.svg";
import forwardArrow from "../../UI/svgs/forwardArrow.svg";
import Button from "../../UI/button/Button";
import { version } from "../../../utility/settings";
const ScreenThree = (props) => {
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);
  //////////////////////////////////////
  return (
    <>
      <AnimatePresence>
        <m.h1
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={classes.screen2Text}
        >
          Last step
          <br />
          Get connected!
        </m.h1>
      </AnimatePresence>
      <div className={`pageHolder wrapper`}>
        <span className={classes.backArrowSettings}>
          {/* <img
            src={backArrow}
            alt={"back arrow icon"}
            className={classes.backArrow}
          /> */}
          <span className={classes.settingsTitle}>
            <span>Settings</span>
          </span>
        </span>
        <div className={classes.settingsGroup}>
          <div className={classes.settingsGroupItems}>
            <span className={classes.settingsLeftItems}></span>
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("FAQ");
            }}
          >
            <span className={classes.settingsLeftItems}></span>
          </div>
          <div className={`${classes.settingsGroupItems} ${classes.highlight}`}>
            <span className={classes.settingsLeftItems}>
              <img
                src={binanceAPI}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Binance API</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>

          <div
            className={`${classes.settingsGroupItems} ${classes.highlight} ${classes.hideItem}`}
          >
            <span
              className={classes.settingsLeftItems}
              onClick={() => {
                props.pageChanger("License");
              }}
            >
              <img
                src={license}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Subscription</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
        </div>
        <span className={classes.textAndButton}>
          <span>
            In the settings connect Callbot with your Binance and earn the first
            10 days subscription-free!
          </span>
        </span>
        <div className={classes.bottomSection}>
          <Button
            className={`${classes.highlight} ${classes.highlightingButton}`}
            onClick={() => {
              fetch(
                `${version}/newUser/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
                {
                  method: "PATCH",
                  body: JSON.stringify({ onBoarded: true }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              ctxPage.onBoardingChanger(false);
              ctx.loginHandler(true);
            }}
          >
            Let's start
          </Button>
        </div>
        <div className={classes.overlayDiv}></div>
      </div>
    </>
  );
};

export default ScreenThree;
