import React, { useState, useEffect, useContext, useRef } from "react";
import backArrow from "../../../../UI/svgs/backArrow.svg";
import "../../../login/Login.css";
import Button from "../../../../UI/button/Button";
import AuthContext from "../../../../../context/auth-context";
import PageContext from "../../../../../context/pageStatusContext";
import eyeClosed from "../../../../UI/svgs/eyeClosed.svg";
import eyeOpen from "../../../../UI/svgs/eyeOpen.svg";
import classes from "./ChangePassword.module.css";
import PasswordUpdated from "../PasswordUpdated/PasswordUpdated";
import { motion as m, AnimatePresence } from "framer-motion";
const ChangePassword = (props) => {
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [eyeToggle, setEyeToggle] = useState(eyeClosed);
  const [passwordText, setPasswordText] = useState("password");
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);

  const passwordOne = useRef();
  const passwordTwo = useRef();

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const submitHandler = (event) => {
    event.preventDefault();
    if (passwordOne.current.value != passwordTwo.current.value) {
      setPasswordIsValid(true);
    } else {
      setPasswordIsValid(false);
      fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
        {
          method: "POST",
          body: JSON.stringify({
            idToken: ctx.sessionToken,
            password: passwordOne.current.value,
            retrunSecureToken: false,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(setVerificationSent(true))
        .catch((error) => console.log(error));
    }
  };
  function eyeToggleHandler() {
    if (passwordText === "password") {
      setEyeToggle(eyeOpen);
      setPasswordText("text");
    } else {
      setEyeToggle(eyeClosed);
      setPasswordText("password");
    }
  }

  return (
    <>
      {!verificationSent && (
        <m.div
          className={`pageHolder wrapper`}
          {...ctxPage.animation}
          key={"changePassword"}
        >
          <img
            src={backArrow}
            alt={"back arrow icon"}
            className={classes.backArrow}
            onClick={() => {
              props.pageChanger("account");
            }}
          />
          <div className={classes.textHolder}>
            <h1>Change Password</h1>
          </div>
          <form
            onSubmit={submitHandler}
            className={classes.loginForm}
            autoComplete="on"
          >
            <label className={"inputFieldPasswordLabel"}>
              <input
                type={passwordText}
                id="passwordOne"
                placeholder={"New Password"}
                ref={passwordOne}
                className={"inputFieldPassword"}
              />
              <img
                id={"togglePassword"}
                src={eyeToggle}
                alt={"password Toggle eye"}
                onClick={eyeToggleHandler}
              />
            </label>
            <label className={["inputFieldPasswordLabel"]}>
              <input
                type={passwordText}
                id="passwordTwo"
                placeholder={"Confirm New Password"}
                ref={passwordTwo}
                className={"inputFieldPassword"}
              />
              <img
                id={"togglePassword"}
                src={eyeToggle}
                alt={"password Toggle eye"}
                onClick={eyeToggleHandler}
              />
            </label>
            <div className={classes.signInButtonHolder}>
              {passwordIsValid && (
                <span style={{ color: "red" }}>Passwords don't match</span>
              )}
              <Button type="submit">Reset</Button>
            </div>
          </form>
        </m.div>
      )}
      {verificationSent && <PasswordUpdated />}
    </>
  );
};

export default ChangePassword;
