import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
  useCircularInputContext,
} from "react-circular-input";
import { Spring, config } from "react-spring/renderprops.cjs";
import React, { useEffect, useState, useContext } from "react";
import classes from "./MainButton.module.css";
import Button from "./Button";
import AuthContext from "../../../../context/auth-context";
import PatternSurround from "./PatternSurround";
import { version } from "../../../../utility/settings";
function MainButton() {
  const ctx = useContext(AuthContext);
  let minStake = 5000;
  ctx.maxUSDT < 300 && (minStake = 3100);
  ctx.maxUSDT === 300 && (minStake = 4592);
  const [value, setValue] = useState(ctx.maxUSDT / minStake);
  const [endValue, setEndValue] = useState();
  function setOnChangeEndValue(value) {
    if (value < 0.0645) {
      setEndValue((Math.round(value * 60) * 100) / 2);
      ctx.maxUSDTChanger((Math.round(value * 60) * 100) / 2);
    } else {
      setEndValue(Math.round(value * 50) * 100);
      ctx.maxUSDTChanger(Math.round(value * 50) * 100);
    }
  }

  useEffect(() => {
    fetch(
      `${version}/${ctx.newUserDB}/${ctx.userId}.json/?auth=${ctx.sessionToken}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          maxUsdt: endValue,
        }),
      }
    );
  }, [endValue]);

  const min = 0;
  const max = 0.98;

  // get value within limits
  const valueWithinLimits = (v) => Math.min(Math.max(v, min), max);
  // custom range
  const range = [0, 100];

  // scaled range value
  const rangeValue = value * (range[1] - range[0]) + range[0];
  return (
    <Spring to={{ value }} config={config.stiff}>
      {(props) => (
        <div className={`${classes.buttonWrapper}`}>
          <div className={classes.buttonHolder}>
            <div className={classes.circle}>
              <CircularInput
                value={props.value}
                onChange={setValue}
                onChangeEnd={setOnChangeEndValue}
              >
                <circle
                  stroke="#fafafa"
                  strokeWidth="25"
                  fill="none"
                  cx="100"
                  cy="100"
                  r="125"
                ></circle>
                <circle fill="white" cx="100" cy="100" r="100"></circle>
                <PatternSurround />
                <CircularTrack className={classes.mn_input} />
                <CircularProgress className={classes.pr_input} />
                <CircularThumb
                  // r={24}
                  fill="rgba(255,255,255,0.5)"
                  className={`${classes.input_thumb}`}
                />
                <CustomComponent />
                <circle
                  xmlns="http://www.w3.org/2000/svg"
                  cx="100"
                  cy="100"
                  r="50"
                  fill="#E9E9E9"
                />
                <circle
                  xmlns="http://www.w3.org/2000/svg"
                  cx="100"
                  cy="100"
                  r="18.1034"
                  fill="#E9E9E9"
                />
              </CircularInput>
            </div>
            <Button onClick={() => {}} />
          </div>
        </div>
      )}
    </Spring>
  );
}

const CustomComponent = () => {
  const [xAxis, setxAxis] = useState();
  const [endValue, setEndValue] = useState();
  const { getPointFromValue, value } = useCircularInputContext();
  const { x, y } = getPointFromValue();
  // this useEffect needs to corret the position of text inside the circle
  useEffect(() => {
    if (value < 0.01) {
      setxAxis(4);
    } else if (value > 0.01 && value < 0.03) {
      setxAxis(7.5);
    } else if (value > 0.03 && value < 0.19) {
      setxAxis(8.5);
    } else if (value > 0.65) {
      setxAxis(14);
    } else {
      setxAxis(11);
    }

    if (value < 0.0646) {
      setEndValue((Math.round(value * 60) * 100) / 2);
    } else {
      setEndValue(Math.round(value * 50) * 100);
    }
  }, [value]);

  return (
    <text
      x={x - xAxis}
      y={y + 3}
      style={{ pointerEvents: "none" }}
      className={classes.thumb_text}
    >
      {endValue}
    </text>
  );
};

export default MainButton;
