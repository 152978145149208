import { useState, useEffect } from "react";

export default function Profit(props) {
  const [profit, setProfit] = useState(props.count);

  useEffect(() => {
    //   const interval = setInterval(() => {
    //     setProfit((prevProfit) => {
    //       if (prevProfit < profit) {
    //         return prevProfit + props.count / 2;
    //       }
    //       return prevProfit;
    //     });
    //   }, 0.01);
    setProfit(props.count);
    //   return () => clearInterval(interval);
  }, [props.count]);

  return <>{profit}</>;
}
