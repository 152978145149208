import { useContext, useState } from "react";
import classes from "./AccountSettings.module.css";
import backArrow from "../../../../UI/svgs/backArrow.svg";
import { motion as m, AnimatePresence } from "framer-motion";
import accountSettings from "../../../../UI/svgs/accountSettings.svg";

import forwardArrow from "../../../../UI/svgs/forwardArrow.svg";
import deleteSVG from "../../../../UI/svgs/delete.svg";
import passwordReset from "../../../../UI/svgs/passwordReset.svg";

import PageContext from "../../../../../context/pageStatusContext";
import DeletConfirmation from "./DeleteConfirmation";

function AccountSettings(props) {
  const ctxPage = useContext(PageContext);
  const [popupVisible, setPopupVisible] = useState(false);

  function deletePopup(props) {
    setPopupVisible(props);
  }

  return (
    <m.div
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
      key={"accountSettings"}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Account Settings</span>
        </span>
      </div>
      <div className={classes.secondPart}>
        <div className={classes.settingsGroup}>
          {/* <div
          className={classes.settingsGroupItems}
          onClick={() => {
            props.pageChanger("PasswordReset");
          }}
        >
          <span className={classes.settingsLeftItems}>
            <img
              src={passwordReset}
              alt={"instructions icon"}
              className={classes.settingsItemIcon}
            />
            <span className={classes.settingsText}>Reset Password</span>
          </span>
          <img
            src={forwardArrow}
            alt={"forward arrow icon"}
            className={classes.settingsItemArrow}
          />
        </div> */}
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageInternalChanger("ChangePassword");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={accountSettings}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Change Password</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
        </div>
        {popupVisible && <DeletConfirmation closePopup={deletePopup} />}

        <div className={classes.bottomSection}>
          <div className={classes.settingsGroupItems}>
            <span
              className={classes.settingsLeftItems}
              onClick={() => deletePopup(true)}
            >
              <img
                src={deleteSVG}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText} style={{ color: "red" }}>
                Delete my account
              </span>
            </span>
          </div>
        </div>
      </div>
    </m.div>
  );
}

export default AccountSettings;
