import { useContext } from "react";
import AuthContext from "../../../../../context/auth-context";
import classes from "./DeleteConfirmation.module.css";
function DeletConfirmation(props) {
  const ctx = useContext(AuthContext);
  function deleteAccount() {
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
      {
        method: "POST",
        body: JSON.stringify({
          idToken: ctx.sessionToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      window.location.reload(false);
      localStorage.clear();
      ctx.logoutHandler();
    });
  }

  return (
    <div className={`${classes.popup} wrapper`}>
      <div className={classes.question}>
        <span>Are you sure?</span>
        <span>All your data will be deleted</span>
      </div>
      <div className={classes.answerLine}>
        <span className={classes.yes} onClick={deleteAccount}>
          <span style={{ color: "red" }}>yes</span>
        </span>
        <span
          className={classes.no}
          onClick={() => {
            props.closePopup(false);
          }}
        >
          <span>no</span>
        </span>
      </div>
    </div>
  );
}
export default DeletConfirmation;
