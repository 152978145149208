import { useContext, useEffect, useState } from "react";

import coins from "../../../../UI/images/callbot.png";
import loss from "../../../../UI/svgs/loss.svg";
import won from "../../../../UI/svgs/won.svg";
import StatusTransaction from "./StatusTransaction/StatusTransaction"; // from the transaction u can call status transaction as
import classes from "./Transaction.module.css";
import { motion as m, AnimatePresence } from "framer-motion";
import acronyms from "../../../../UI/acronyms/acronyms.json";
import * as DB from "firebase/database";
import { database } from "../../../../../utility/firebase";
import PageContext from "../../../../../context/pageStatusContext";
let lossImg = <img src={loss} alt={"lossIcon"} />;
let wonImg = <img src={won} alt={"lossIcon"} />;

function Transaction(props) {
  const [clicked, setClicked] = useState(false);
  const [image, setImage] = useState();
  const [tradeColor, setTradeColor] = useState();
  const [openCoinPrice, setOpenCoinPrice] = useState(
    Math.round(props.signals.invested * 100) / 100
  );
  const ctxPage = useContext(PageContext);

  // console.log(props.signals);
  const [state, setState] = useState({
    color: "",
    symbol: "",
    usdt: "",
    graph: "",
    status: props.signals.status,
    amount: Math.round(
      (props.signals.price_closing - props.signals.entry_price) *
        props.signals.quantity
    ),
    entryPrice: props.signals.entry_price,
    pair: props.signals.pair.slice(0, -5),
    takeWin: props.signals.take_win,
    stopLoss: props.signals.stop_loss,
    invested: props.signals.invested,
    quantity: props.signals.quantity,
    priceClosing: props.signals.price_closing,
    return: props.signals.return,
    timeClosing: props.signals.time_closing,
    comments: props.signals.comments,
    signalStatus: "",
    backGround: "",
  });

  useEffect(() => {
    if (
      props.signals.status === "closed" &&
      props.signals.comments === "Stop-Loss reached"
    ) {
      setTradeColor(classes.loss);
      setState({
        ...state,
        color: classes.loss,
        symbol: loss,
        usdt: "$",
        graph: lossImg,
        status: "Closed",
        backGround: classes.backGroundLoss,
      });
    } else if (
      props.signals.status === "closed" &&
      props.signals.comments === "Take-Profit reached"
    ) {
      setTradeColor(classes.won);
      setState({
        ...state,
        color: classes.won,
        symbol: won,
        usdt: "$",
        graph: wonImg,
        status: "Closed",
        backGround: classes.backGroundWon,
      });
    } else if (props.signals.status === "open") {
      setState({
        ...state,
        usdt: "$",
        color: classes.open,
        status: "Open",
        backGround: classes.backGroundOpen,
      });
    } else if (props.signals.status === "ordered") {
      setState({
        ...state,
        usdt: "$",
        color: classes.open,
        status: "Open",
        backGround: classes.backGroundOpen,
      });
    } else if (props.signals.status === "canceled") {
      setState({
        ...state,
        color: classes.notInvested,
        status: "Missed",
        amount: "",
        backGround: classes.backGroundNotInvested,
      });
    }
  }, []);

  function closingModal(props) {
    setClicked(props);
  }
  // console.log(props.signals.pair.slice(0, -5));
  let obj = acronyms.findIndex(
    (o) => o.pair === props.signals.pair.slice(0, -5)
  );
  let coinFullName = "";
  acronyms[obj]
    ? (coinFullName = acronyms[obj].coin)
    : (coinFullName = props.signals.pair.slice(0, -5));
  useEffect(() => {
    async function img() {
      await import(
        `../../../../../../node_modules/cryptocurrency-icons/svg/color/${props.signals.pair
          .slice(0, -5)
          .toLowerCase()}.svg`
      )
        .then((imp) => setImage(imp.default))
        .catch((err) => setImage(coins));
    }
    img();

    //Ask binance for current price
    if (props.signals.status === "open") {
      const symbol = props.signals.pair.replace("/", "");
      const endpoint = `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`;
      setInterval(() => {
        fetch(endpoint)
          .then((response) => response.json())
          .then((data) => {
            const lastMarketPrice =
              Math.round(data.price * props.signals.quantity * 100) / 100;
            setOpenCoinPrice(lastMarketPrice);
            if (lastMarketPrice > 0) {
              setTradeColor(classes.won);
            } else if (lastMarketPrice < 0) {
              setTradeColor(classes.loss);
            }
            const openSignalsProfit =
              (data.price - props.signals.entry_price) * props.signals.quantity;
            ctxPage.openSignalsAMountChanger(openSignalsProfit); // this will go to total earned
          })
          .catch((error) => console.error(error));
      }, 3000);
    }
  }, []);

  // checking the coins on common branch
  useEffect(() => {
    if (
      !props.signals.time_ordered.includes(":") &&
      !props.signals.time_ordered.includes("-")
    ) {
      let starCountRef = DB.ref(
        database,
        "userSignals/2H8paN9F7xUEOBTVyaWyn8EYpLn2/transactions"
      );
      if (props.signals.status === "canceled") {
        let recentSignals = DB.query(
          starCountRef,
          DB.orderByChild("time_ordered"),
          DB.equalTo(`${props.signals.time_ordered}`)
        );
        DB.onValue(recentSignals, (snapshot) => {
          const dataObject = Object.entries(snapshot.val()).reverse();
          // dataObject[1].return && setMissedOpportunity(true);
          checkNotInvested(dataObject[0][1].return);
          // console.log(dataObject[0][1].return);
        });
      }
    }
  }, []);

  function checkNotInvested(value) {
    value === true &&
      setState({
        ...state,
        color: classes.won,
        status: "Missed",
        signalStatus: true,
        backGround: classes.backGroundWon,
      });
    value === false &&
      setState({
        ...state,
        color: classes.loss,
        status: "Missed",
        backGround: classes.backGroundLoss,
        signalStatus: false,
      });
    if (value === "-" && props.signals.status === "canceled") {
      setState({
        ...state,
        color: classes.open,
        backGround: classes.backGroundOpen,
        status: "Missed",
      });
    }
  }
  return (
    <>
      {props.visible && (
        <AnimatePresence mode="sync">
          {clicked ? (
            <StatusTransaction
              coinImage={image}
              signals={state}
              onClick={closingModal}
              pair={props.signals.pair}
              coinFullName={coinFullName}
            />
          ) : (
            ""
          )}
        </AnimatePresence>
      )}

      <m.div
        className={classes.transactionLine}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7 }}
        exit={{ opacity: 0 }}
        onClick={() => {
          setClicked(true);
        }}
      >
        <div className={classes.leftPart}>
          <div className={classes.CoinleftPart}>
            <img
              src={image}
              alt={"coinImage"}
              className={`${classes.coinImage} ${state.backGround}`}
            />
            <span className={classes.coinPairHolder}>
              <span className={classes.coinPairFullName}>
                {props.signals.pair.slice(0, -5)}
              </span>
              <span className={`${state.color} ${classes.status}`}>
                {state.status}
              </span>
            </span>
          </div>
          <div className={classes.middleLine}>
            <div className={classes.leftDiv}></div>
            <span className={classes.righPart}>
              <span className={`${classes.transcationValueUp} ${state.color} `}>
                {state.status === "Closed" && (
                  <img src={state.symbol} alt={"symbol"} />
                )}
                {isNaN(
                  Math.round(
                    (props.signals.price_closing - props.signals.entry_price) *
                      props.signals.quantity
                  )
                )
                  ? ""
                  : Math.round(
                      Math.abs(state.priceClosing * state.quantity)
                    )}{" "}
                {props.signals.status === "open" && openCoinPrice}{" "}
                {props.signals.status === "ordered" && state.invested}{" "}
                {state.usdt}
              </span>
              <span className={`${classes.transcationValueDown} `}>
                <span className={classes.transcationSymbol}></span>
                {isNaN(
                  Math.round(
                    (props.signals.price_closing - props.signals.entry_price) *
                      props.signals.quantity
                  )
                )
                  ? ""
                  : Math.round(
                      Math.abs(
                        props.signals.entry_price * props.signals.quantity
                      )
                    )}{" "}
                {props.signals.status === "open" && state.invested}{" "}
                {props.signals.status === "ordered" && state.invested}
                {state.usdt}
              </span>
            </span>
          </div>
        </div>
      </m.div>
    </>
  );
}

export default Transaction;
