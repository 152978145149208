import ibot from "../../../UI/images/iBotReset.png";
import ibotShade from "../../../UI/images/iBotShade.png";
import classes from "./Tutorial.module.css";
import backArrow from "../../../UI/svgs/backArrow.svg";
import { useContext } from "react";
import PageContext from "../../../../context/pageStatusContext";
import tutorialVideo from "../../../UI/images/tutorial.mp4";
import { motion as m } from "framer-motion";
function Tutorial(props) {
  const ctxPage = useContext(PageContext);
  // const data = {
  //   title: "FAQ (How it works)",
  //   rows: [
  //     {
  //       title: "Tutorial,",
  //       content: (
  //         <video
  //           src={tutorialVideo}
  //           width="300"
  //           height="600"
  //           controls="controls"
  //           autoplay="true"
  //         ></video>
  //       ),
  //     },
  //   ],
  // };
  // const styles = {
  //   // bgColor: 'white',
  //   titleTextColor: "black",
  //   rowTitleColor: "black",
  //   fontFamily: "Poppins400",
  //   // rowContentColor: 'grey',
  //   // arrowColor: "red",
  // };

  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          props.pageChanger("Settings");
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Tutorial</span>
        </span>
      </div>

      <div className={classes.iBotHolder}>
        <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
        <img
          src={ibotShade}
          className={classes.iBotShade}
          alt={"welcoming bot shade"}
        />
      </div>
      <div className={classes.textHolder}>
        <video
          src={tutorialVideo}
          width="300"
          height="600"
          controls="controls"
          autoplay="true"
        ></video>
        {/* <Faq data={data} styles={styles} config={config} /> */}
      </div>
    </m.div>
  );
}

export default Tutorial;
