import ibot from "../../UI/images/iBot.png";
import ibotShade from "../../UI/images/iBotShade.png";
import classes from "./Loading.module.css";

function LoadingNew() {
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
        </div>
        <div className={classes.textHolder}>
          <div className={classes.introText}>Loading...</div>
        </div>
        <div className={classes.buttonsHolder}></div>
      </div>
    </div>
  );
}

export default LoadingNew;
