import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/app";
import { getAuth } from "firebase/auth";
import * as DB from "firebase/database";
import firebase from "firebase/app";
import "firebase/auth";
import { version } from "./settings";

const firebaseConfigLive = {
  apiKey: "AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
  authDomain: "callbottest-d1644.firebaseapp.com",
  databaseURL:
    "https://callbottest-d1644-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "callbottest-d1644",
  storageBucket: "callbottest-d1644.appspot.com",
  messagingSenderId: "706621532162",
  appId: "1:706621532162:web:ee15e840db4eac41311f1b",
};

const firebaseConfigTest = {
  apiKey: "AIzaSyC_9cuQfRUkUCLMVBx9MNjAGN32twl01qo",
  authDomain: "callbot-78a18.firebaseapp.com",
  databaseURL:
    "https://callbot-78a18-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "callbot-78a18",
  storageBucket: "callbot-78a18.appspot.com",
  messagingSenderId: "314531789333",
  appId: "1:314531789333:web:534d6630021e863b932c1c",
  measurementId: "G-QQRPPSWKQ1",
};
let versionFirebase = "";
function fireBaseVersionChanger() {
  if (
    version ===
    "https://callbot-78a18-default-rtdb.europe-west1.firebasedatabase.app"
  ) {
    let versionFirebase = firebaseConfigLive;
    return versionFirebase;
  } else {
    let versionFirebase = firebaseConfigTest;
    return versionFirebase;
  }
}
// fireBaseVersionChanger();
let app = initializeApp(firebaseConfigLive);
export const auth = getAuth(app);
export const database = DB.getDatabase(app);

// console.log(database.DataSnapshot.once());
