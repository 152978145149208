import { createContext, useState } from "react";

const AuthContext = createContext({
  userId: "", // ID from Firebase
  userIdChanger: () => {}, // Changes the UserID
  userValidated: false, // Validated API
  userValidation: () => {},
  loginHandler: () => {},
  isLoggedIn: false, // when customer Logged In
  logoutHandler: () => {},
  checkedStatus: false, // if the Button Checked or Not
  checkStatusHandler: () => {},
  sessionToken: "", // token from Firebase, to get the access to the database
  sessionTokenChanger: () => {},
  maxUSDT: 0,
  maxUSDTChanger: () => {},
  apiKeyNotExist: false,
  apiKeyNotExistChanger: () => {},
  maxInvest: 10000,
  maxInvestChanger: () => {},
  newUserDB: "",
  newUserDBChanger: () => {},
  branch: "",
  branchChanger: () => {},
  refLink: "",
  refLinkChanger: () => {},
  wallet: "",
  walletChanger: () => {},
});

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [maxUSDT, setMaxUSDT] = useState(0);
  const initialToken = localStorage.getItem("customerToken");
  const [userId, setUserId] = useState("");
  const [userValidated, setUserValidated] = useState(false);
  const [checkedStatus, setCheckStatus] = useState(false);
  const [sessionToken, setSessionToken] = useState(initialToken);
  const [apiKeyNotExist, setApiKeyNotExist] = useState(false);
  const [maxInvest, setMaxInvest] = useState(10000);
  const [newUserDB, setNewUserDB] = useState("newUser");
  const [branch, setBranch] = useState("2H8paN9F7xUEOBTVyaWyn8EYpLn2");
  const [wallet, setWallet] = useState("");
  const [refLink, setRefLink] = useState("");
  ////// Function to control user logged in or not

  const logoutHandler = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    setIsLoggedIn(true);
  };
  const userIdChanger = (userId) => {
    setUserId(userId);
  };

  const userValidation = (props) => {
    setUserValidated(props);
  };
  const checkStatusHandler = (props) => {
    setCheckStatus(props);
  };
  const sessionTokenChanger = (props) => {
    setSessionToken(props);
  };
  const maxUSDTChanger = (props) => {
    setMaxUSDT(props);
  };
  const apiKeyNotExistChanger = (props) => {
    setApiKeyNotExist(props);
  };
  const maxInvestChanger = (props) => {
    setMaxInvest(props);
  };
  const newUserDBChanger = (props) => {
    setNewUserDB(props);
  };
  const branchChanger = (props) => {
    setBranch(props);
  };
  const walletChanger = (props) => {
    setWallet(props);
  };
  const refLinkChanger = (props) => {
    setRefLink(props);
  };

  return (
    <AuthContext.Provider
      value={{
        userId, // ID from Firebase
        userIdChanger, // Changes the UserID
        userValidated, // Validated API
        userValidation,
        loginHandler,
        isLoggedIn, // when customer Logged In
        logoutHandler,
        checkedStatus, // if the Button Checked or Not
        checkStatusHandler,
        sessionToken, // token from Firebase, to get the access to the database
        sessionTokenChanger,
        maxUSDT,
        maxUSDTChanger,
        apiKeyNotExist,
        apiKeyNotExistChanger,
        maxInvest,
        maxInvestChanger,
        newUserDB,
        newUserDBChanger,
        branch,
        branchChanger,
        wallet,
        walletChanger,
        refLink,
        refLinkChanger,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
