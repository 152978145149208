import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../../../context/auth-context";
import classes from "./history.module.css";
import HistoryNewAll from "./HistoryCards/HistoryNew all";
import Filter from "./Filter/Filter";
import HistoryNewClosed from "./HistoryCards/HistoryNew closed";
import HistoryNewMissed from "./HistoryCards/HistoryNew missed";
import HistoryNewOpen from "./HistoryCards/HistoryNew open";
import SummaryManagement from "./Summary/SummaryManagement";
import Bonbon from "./Summary/Bonbon/Bonbon";

function History(props) {
  const [stylesAll, setStylesAll] = useState();
  const [stylesOpen, setStylesOpen] = useState();
  const [stylesClosed, setStylesClosed] = useState();
  const [stylesMissed, setStylesMissed] = useState();

  let toCenter = {
    transform: "translateX(0)",
    opacity: 1,
    transition: "transform 0.5s ease-out, opacity 0.5s",
    position: "relative",
  };
  let toLeft = {
    transform: "translateX(-100%)",
    opacity: 0,
    transition: "transform 0.5s ease-out, opacity 0.5s linear",
    position: "absolute",
  };
  let toRight = {
    transform: "translateX(100%)",
    opacity: 0,
    transition: "transform 0.5s ease-out, opacity 0.5s linear",
    position: "absolute",
  };
  const ctx = useContext(AuthContext);

  function filterClicked(state) {
    if (state === "All") {
      setStylesAll(toCenter);
      setStylesOpen(toRight);
      setStylesClosed(toRight);
      setStylesMissed(toRight);
    } else if (state === "Open") {
      setStylesAll(toLeft);
      setStylesOpen(toCenter);
      setStylesClosed(toRight);
      setStylesMissed(toRight);
    } else if (state === "Closed") {
      setStylesAll(toLeft);
      setStylesOpen(toLeft);
      setStylesClosed(toCenter);
      setStylesMissed(toRight);
    } else if (state === "Missed") {
      setStylesAll(toLeft);
      setStylesOpen(toLeft);
      setStylesClosed(toLeft);
      setStylesMissed(toCenter);
    }
  }
  return (
    <div className={`${classes.parent}`}>
      <div className={classes.sticky}>
        <SummaryManagement summaryClass={classes.summary} />
        <Filter onClick={filterClicked} />
      </div>

      <div className={classes.historyCards}>
        <div
          className={`${classes.historyCard} ${classes.historyCardAll}`}
          style={stylesAll}
        >
          <HistoryNewAll />
        </div>
        <div
          className={`${classes.historyCardOpen} ${classes.historyCard}`}
          style={stylesOpen}
        >
          <HistoryNewOpen />
        </div>
        <div
          className={`${classes.historyCardClosed} ${classes.historyCard}`}
          style={stylesClosed}
        >
          <HistoryNewClosed />
        </div>
        <div
          className={`${classes.historyCardMissed} ${classes.historyCard}`}
          style={stylesMissed}
        >
          <HistoryNewMissed />
        </div>
      </div>
    </div>
  );
}

export default History;
