import { useContext } from "react";
import PageContext from "../../../../../../context/pageStatusContext";
import classes from "./APIExpired.module.css";
import alert from "../../../../../UI/svgs/alert.svg";
import smallArrow from "../../../../../UI/svgs/smallArrow.svg";
function APIExpired() {
  const ctxPage = useContext(PageContext);

  function goToSettings() {
    ctxPage.settingsStatusChanger(true);
  }
  return (
    <div className={classes.statusHolder}>
      <img src={alert} alt={"alert"} />
      <div className={classes.messageHolder}>
        <span className={classes.mainMessage}>
          Your Binance API has expired or is not correct. Please update it.{" "}
        </span>
        <span className={classes.goToSettingsLine} onClick={goToSettings}>
          <span className={classes.goToSettingsText}>Go to settings </span>
          <img
            src={smallArrow}
            alt={"small arrow icon"}
            className={classes.smallArrow}
          />
        </span>
      </div>
    </div>
  );
}

export default APIExpired;
