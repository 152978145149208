import { useContext } from "react";
import AuthContext from "../../../../context/auth-context";
import PageContext from "../../../../context/pageStatusContext";
import Button from "../../../UI/button/Button";
import ibot from "../../../UI/images/iBotReset.png";
import ibotShade from "../../../UI/images/iBotShade.png";
import classes from "./ThankYou.module.css";

function ThankYou() {
  const ctxPage = useContext(PageContext);
  function logout() {
    ctxPage.loginPageChanger(true);
    ctxPage.signUpStatusChanger(false);
  }
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
          <img
            src={ibotShade}
            className={classes.iBotShade}
            alt={"welcoming bot shade"}
          />
        </div>
        <div className={classes.textHolder}>
          <h1>Congratulation</h1>
          <div className={classes.introText}>
            You will receive a confirmation email.
          </div>
        </div>
        <div className={classes.buttonsHolder}>
          <Button onClick={logout}>Login</Button>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
