import React, { useContext } from "react";

import classes from "./settings.module.css";
import AuthContext from "../../../context/auth-context";
import PageContext from "../../../context/pageStatusContext";
import backArrow from "../../UI/svgs/backArrow.svg";
import promotion from "../../UI/svgs/promotion.svg";
import instructions from "../../UI/svgs/instructions.svg";
import binanceAPI from "../../UI/svgs/binanceAPI.svg";
import accountSettings from "../../UI/svgs/accountSettings.svg";
import license from "../../UI/svgs/license.svg";
import forwardArrow from "../../UI/svgs/forwardArrow.svg";
import logout from "../../UI/svgs/logout.svg";
import ibot from "../../UI/images/iBotmini.png";
import ibotShade from "../../UI/images/iBotShade.png";
import { motion as m, AnimatePresence } from "framer-motion";

const Settings = (props) => {
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);

  //////////////////////////////////////
  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      <div
        className={classes.backArrowSettings}
        onClick={() => {
          ctxPage.settingsStatusChanger(false);
        }}
      >
        <img
          src={backArrow}
          alt={"back arrow icon"}
          className={classes.backArrow}
        />
        <span className={classes.titleCenter}>
          <span className={classes.settingsTitle}>Settings</span>
        </span>
      </div>
      <div className={classes.secondPart}>
        {" "}
        <div className={classes.settingsGroup}>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("Account");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={accountSettings}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Account Settings</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("Tutorial");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={instructions}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Tutorial</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("FAQ");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={instructions}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>FAQ</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("Promotion");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={promotion}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Referral Program</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              props.pageChanger("Binance");
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={binanceAPI}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Binance API</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>

          <div className={classes.settingsGroupItems}>
            <span
              className={classes.settingsLeftItems}
              onClick={() => {
                props.pageChanger("License");
              }}
            >
              <img
                src={license}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Subscription</span>
            </span>
            <img
              src={forwardArrow}
              alt={"forward arrow icon"}
              className={classes.settingsItemArrow}
            />
          </div>
        </div>
        <div className={classes.bottomSection}>
          <div
            className={classes.ibotSection}
            onClick={() => {
              props.pageChanger("ContactUs");
            }}
          >
            <span className={classes.ibotHolder}>
              <img src={ibot} alt={"ibot img"} className={classes.ibot} />
              <img
                src={ibotShade}
                alt={"ibot shade"}
                className={classes.ibotShade}
              />
            </span>
            <span className={classes.iBotSectionText}>How can I help?</span>
          </div>
          <div
            className={classes.settingsGroupItems}
            onClick={() => {
              ctx.logoutHandler();
            }}
          >
            <span className={classes.settingsLeftItems}>
              <img
                src={logout}
                alt={"instructions icon"}
                className={classes.settingsItemIcon}
              />
              <span className={classes.settingsText}>Log out</span>
            </span>
          </div>
        </div>
      </div>
    </m.div>
  );
};

export default Settings;
