import x from "../../../../../UI/svgs/x.svg";
import smallArrow from "../../../../../UI/svgs/smallArrow.svg";
import classes from "./StatusTransaction.module.css";
import DraggableCore from "react-draggable";
import React, { useState } from "react";
import { createPortal } from "react-dom";

import { motion as m, AnimatePresence } from "framer-motion";
function StatusTransaction(props) {
  const [initialPosition, setIntialPosition] = useState();
  const trackPos = (data) => {
    if (data.y > 100) {
      props.onClick(false);
    }
  };
  function draggableStoped(data) {
    props.onClick(false);
  }
  return createPortal(
    <div className={`${classes.statusHolderOverall} wrapper`}>
      <m.div
        className={classes.overlayDiv}
        onClick={() => {
          props.onClick(false);
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7 }}
        exit={{ opacity: 0 }}
        key={props.key + "overlay"}
      ></m.div>
      <div className={`${classes.statusHolderParentLayout} `}>
        <DraggableCore
          axis="y"
          // onDrag={(e, data) => trackPos(data)}
          bounds="parent"
          onStop={draggableStoped}
          cancel=".binanceView"
        >
          {/* we have the style for draggable component in index.css */}
          <div style={initialPosition} className={classes.widthAdapter}>
            <m.div //
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              key={props.key + "statusBox"}
              transition={{ duration: 0.3, ease: "easeOut" }}
              exit={{ y: "100%" }}
              className={`${classes.statusHolder}`}
            >
              <img
                src={x}
                alt={"closing svg"}
                className={classes.closingIcon}
                onClick={() => {
                  props.onClick(false);
                }}
              />
              <div className={classes.coinStatusHolder}>
                <span className={classes.coinImgName}>
                  <img
                    src={props.coinImage}
                    alt={"closing svg"}
                    className={classes.coinImage}
                  />
                  <span className={classes.coinName}>
                    <span className={classes.coinShortName}>
                      {" "}
                      {props.signals.pair}
                    </span>
                    <span className={classes.coinFullName}>
                      {" "}
                      {props.coinFullName}
                    </span>
                  </span>
                </span>
                <span className={`${props.signals.color} ${classes.status}`}>
                  {props.signals.status}
                </span>
              </div>
              <div className={classes.analyticsPArt}>
                <span className={classes.title}>Analytics Prediction</span>
                <div className={classes.history}>
                  <div>
                    Entry Price: <span>{props.signals.entryPrice} </span>
                  </div>
                  <div>
                    Take Win: <span>{props.signals.takeWin} </span>
                  </div>
                  <div>
                    Stop Loss: <span>{props.signals.stopLoss} </span>
                  </div>
                  <a
                    href={`https://www.binance.com/en/trade/${props.signals.pair}_USDT`}
                    className={`${classes.binanceView} binanceView`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Binance View <img src={smallArrow} alt={"smallarrow"} />
                  </a>
                </div>
              </div>

              <div className={classes.transactionPart}>
                <span className={classes.title}> Transaction Info</span>
                <div className={classes.history}>
                  {props.signals.status != "missed" && (
                    <>
                      <div>
                        Invested: <span>{props.signals.invested}</span>
                      </div>
                      <div>
                        Closing Price: <span>{props.signals.priceClosing}</span>
                      </div>
                    </>
                  )}
                  <div>
                    Return:{" "}
                    {props.signals.signalStatus ? (
                      <span> Won, but You missed it </span>
                    ) : (
                      <span>{props.signals.return}</span>
                    )}
                  </div>
                  <div>
                    Time: <span>{props.signals.timeClosing}</span>
                  </div>
                  <div>
                    Info: <span>{props.signals.comments}</span>
                  </div>
                </div>
              </div>
            </m.div>
          </div>
        </DraggableCore>
      </div>
    </div>,
    document.getElementById("root")
  );
}

export default StatusTransaction;
