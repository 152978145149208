import { motion as m } from "framer-motion";
import classes from "./OverlayDiv.module.css";

export default function OverlayDiv(props) {
  return (
    <m.div
      className={classes.overlayDiv}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
      exit={{ opacity: 0 }}
      key={"overlay"}
      onClick={props.closeModal}
    ></m.div>
  );
}
