import { useContext } from "react";
import AuthContext from "../../../../../context/auth-context";
import Button from "../../../../UI/button/Button";
import ibot from "../../../../UI/images/iBotReset.png";
import ibotShade from "../../../../UI/images/iBotShade.png";
import classes from "./PasswordUpdated.module.css";

function PasswordUpdated() {
  const ctx = useContext(AuthContext);
  function logout() {
    ctx.logoutHandler();
  }
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
          <img
            src={ibotShade}
            className={classes.iBotShade}
            alt={"welcoming bot shade"}
          />
        </div>
        <div className={classes.textHolder}>
          <h1>Password Updated</h1>
          <div className={classes.introText}>
            Your password has been updated
          </div>
        </div>
        <div className={classes.buttonsHolder}>
          <Button onClick={logout}>Login</Button>
        </div>
      </div>
    </div>
  );
}

export default PasswordUpdated;
