import React, { useContext } from "react";
import AuthContext from "../../../context/auth-context";
import PageContext from "../../../context/pageStatusContext";
import classes from "./GoogleButton.module.css";
import { auth } from "../../../utility/firebase";
import google from "../../UI/svgs/google.svg";
import { version } from "../../../utility/settings";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { signInfunc } from "../../../utility/settings";

const GoogleButton = (props) => {
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);
  const googleProvider = new GoogleAuthProvider();

  async function googleSignin() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = result._tokenResponse;
      const idToken = result._tokenResponse.idToken;
      const localId = result._tokenResponse.localId;
      const refreshToken = result._tokenResponse.refreshToken;
      token.isNewUser
        ? googleSigninCreateUserInDB(idToken, localId)
        : signInfunc(idToken, localId, ctx, ctxPage);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("localId", localId);
      localStorage.setItem("refT", refreshToken);
    } catch (err) {
      console.log(err);
    }
  }
  function googleSigninCreateUserInDB(idToken, localId) {
    ctxPage.onBoardingChanger(true);
    ctxPage.signUpStatusChanger(false);
    const currentURL = window.location.href;
    let refLinkId = "";
    if (currentURL.length > 26) {
      refLinkId = window.location.href.substring(
        window.location.href.indexOf("?rid=") + 5
      );
    }
    const userCreationDataButton = {
      [localId]: {
        APIkey: "",
        buttonOnOff: false,
        maxUsdt: 100,
        secretKey: "",
        userId: localId,
        onBoarded: false,
        maxInvest: 10000,
        refLink: {
          signUp: refLinkId,
        },
        wallet: "",
      },
    };
    fetch(`${version}/newUser.json/?auth=${idToken}`, {
      method: "PATCH",
      body: JSON.stringify(userCreationDataButton),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => signInfunc(idToken, localId, ctx, ctxPage))
      .catch((err) => console.log(err));
  }

  return (
    <button
      type={props.type || "button"}
      className={`${classes.gbutton} ${props.className}`}
      onClick={googleSignin}
      disabled={props.disabled}
    >
      <img src={google} alt={"googleLogo"} /> Sign in with Google
    </button>
  );
};

export default GoogleButton;
