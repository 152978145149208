import { useEffect, useState } from "react";
import AccountPages from "./AccountSettings/AccountPages";
import SettingsAPI from "./API/SettingsAPI";
import ContactUs from "./ContactUs/ContactUs";
import Instructions from "./Tutorial/Tutorial";
import License from "./License/License";
import Settings from "./Settings";
import { AnimatePresence } from "framer-motion";
import FAQ from "./FAQ/FAQ";
import Tutorial from "./Tutorial/Tutorial";
import Promotion from "./Promotion/Promotion";
function SettingsPages() {
  const [page, setPage] = useState("Settings");
  function pageChanger(props) {
    setPage(props);
  }
  return (
    <>
      <AnimatePresence mode="wait">
        {page === "Settings" && (
          <Settings pageChanger={pageChanger} key={"SettingsPage"} />
        )}
        {/* </AnimatePresence>
      <AnimatePresence mode="sync"> */}
        {page === "License" && (
          <License pageChanger={pageChanger} key={"LicensePage"} />
        )}
        {page === "FAQ" && <FAQ pageChanger={pageChanger} key={"FAQPage"} />}
        {page === "Tutorial" && (
          <Tutorial pageChanger={pageChanger} key={"FAQPage"} />
        )}
        {page === "Account" && (
          <AccountPages pageChanger={pageChanger} key={"AccountPage"} />
        )}
        {page === "ContactUs" && (
          <ContactUs pageChanger={pageChanger} key={"ContactUsPage"} />
        )}
        {page === "Binance" && (
          <SettingsAPI pageChanger={pageChanger} key={"BinancePage"} />
        )}
        {page === "Promotion" && (
          <Promotion pageChanger={pageChanger} key={"Promotion"} />
        )}
      </AnimatePresence>
    </>
  );
}

export default SettingsPages;
