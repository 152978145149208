import React, { useContext, useEffect, useState } from "react";
import Login from "./components/cards/login/Login";
import "./App.css";
import AuthContext from "./context/auth-context";
import PageContext from "./context/pageStatusContext";
import SignUp from "./components/cards/registration/SignUp";
import PasswordReset from "./components/cards/passwordReset/PasswordReset";
import Welcome from "./components/cards/welcome/Welcome";
import Home from "./components/cards/home/Home";
import SettingsPages from "./components/cards/settings/SettingsPages";
import Onboarding from "./components/cards/onboarding/screensManagement";
import Loading from "./components/cards/loading/Loading";
import { version } from "./utility/settings";
import { signInfunc } from "./utility/settings";
import Verified from "./components/cards/registration/Verified/Verified";

export default function App() {
  ////////////////////

  const [loading, setLoading] = useState(true);

  const [newRegistered, setNewRegistered] = useState(false);

  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);
  const loadingAppearance = () => {
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  useEffect(() => {
    const localId = localStorage.getItem("localId");
    const idToken = localStorage.getItem("idToken");
    const refreshToken = localStorage.getItem("refT");
    // console.log(refreshToken);
    if (localId && idToken) {
      apiCaller(idToken, localId, refreshToken);
      async function apiCaller(idToken, localId, refreshToken) {
        fetch(
          "https://securetoken.googleapis.com/v1/token?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
          {
            method: "POST",
            body: JSON.stringify({
              grant_type: "refresh_token",
              refresh_token: refreshToken,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((res) => {
            ctx.sessionTokenChanger(res.id_token);
            async function signin() {
              console.log("render");
              await signInfunc(res.id_token, res.user_id, ctx, ctxPage);
              localStorage.setItem("idToken", res.id_token);
              localStorage.setItem("localId", res.user_id);
              localStorage.setItem("refT", res.refresh_token);
              loadingAppearance();
            }
            signin();

            fetch(
              `${version}/userSignals/${localId}.json/?auth=${res.id_token}`
            )
              .then((response) => {
                const data = response.json();
                return data;
              })
              .then((response) => {
                response != null &&
                  ctx.apiKeyNotExistChanger(!response.general_data.api_correct);
                loadingAppearance();
              })
              .catch((err) => {
                console.log(err);
                loadingAppearance();
              });
          });
      }
    } else {
      loadingAppearance();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode");
    if (mode == "verifyEmail") {
      const oob = queryParams.get("oobCode");
      setNewRegistered(true);
      // replace001
      fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyDA7kcm2ef7Y6gSvG90aeUqvnFkRzsfR5s",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            oobCode: oob,
          }),
          redirect: "follow",
        }
      )
        .then((result) => {
          window.history.replaceState(null, "New Page Title", "/");
        })
        .catch((error) => console.log("error", error));
      const timeout = setTimeout(() => {
        setNewRegistered(false);
      }, 3000);
      ctxPage.loginPageChanger(true);
    }
  });
  if (loading) {
    return <Loading />;
  } else {
    if (newRegistered) {
      return <Verified />;
    } else {
      if (ctx.isLoggedIn) {
        if (ctxPage.settingsStatus) {
          return <SettingsPages />;
        } else {
          return <Home />;
        }
      } else {
        if (ctxPage.signUpStatus) {
          return <SignUp />;
        }
        if (ctxPage.onBoarding) {
          return <Onboarding />;
        }
        if (ctxPage.resetPasswordStatus) {
          return <PasswordReset />;
        }
        if (ctxPage.loginPage) {
          return <Login />;
        } else {
          return <Welcome />;
        }
      }
    }
  }
}

// function App() {
//   return <SignUp />;
// }
// export default App;
