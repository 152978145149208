import { createBox } from "framer-motion";
import { useContext } from "react";
import PageContext from "../../../context/pageStatusContext";
import Button from "../../UI/button/Button";
import ibot from "../../UI/images/iBot.png";
import ibotShade from "../../UI/images/iBotShade.png";
import classes from "./Welcome.module.css";

function Welcome() {
  const ctxPage = useContext(PageContext);


  
  return (
    <div className={classes.welcomePageBck}>
      <div className={`pageHolder wrapper`}>
        <div className={classes.iBotHolder}>
          <img src={ibot} className={classes.iBot} alt={"welcoming bot"} />
          <img
            src={ibotShade}
            className={classes.iBotShade}
            alt={"welcoming bot shade"}
          />
        </div>
        <div className={classes.textHolder}>
          <h1 className={classes.statement}>Earn with CallBot!</h1>
          <div className={classes.introText}>
            Automate your smart investments.
            <br />
            First AI-spot trader based on big data analytics.
          </div>
        </div>
        <div className={classes.buttonsHolder}>
          <Button
            onClick={() => {
              ctxPage.signUpStatusChanger(true);
            }}
          >
            Register
          </Button>
          <Button
            onClick={() => {
              ctxPage.loginPageChanger(true);
            }}
            className={classes.signInButton}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
