import React, { useState, useEffect, useContext, useRef } from "react";
import backArrow from "../../../UI/svgs/backArrow.svg";
import "../../login/Login.css";
import Button from "../../../UI/button/Button";
import AuthContext from "../../../../context/auth-context";
import PageContext from "../../../../context/pageStatusContext";
import classes from "./ContactUs.module.css";
import contactUs from "../../../UI/svgs/contactUs.svg";
import Confirmation from "./Confirmation/Confirmation";
import { motion as m, AnimatePresence } from "framer-motion";
import { version } from "../../../../utility/settings";
const ContactUs = (props) => {
  const [fieldEmpty, setFieldEmpty] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const ctx = useContext(AuthContext);
  const ctxPage = useContext(PageContext);

  const name = useRef();
  const message = useRef();

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const submitHandler = (event) => {
    event.preventDefault();
    if (name.current.value === "" || message.current.value === "") {
      setFieldEmpty(false);
    }
    let contactData = {
      [ctx.userId]: {
        name: name.current.value,
        message: message.current.value,
        replied: false,
      },
    };
    fetch(`${version}/userMessages.json/?auth=${ctx.sessionToken}`, {
      method: "POST",
      body: JSON.stringify(contactData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setConfirmed(true);
  };
  return (
    <m.div
      key={props.key}
      className={`pageHolder wrapper`}
      {...ctxPage.animation}
    >
      {!confirmed ? (
        <>
          <div
            className={classes.backArrowSettings}
            onClick={() => {
              props.pageChanger("Settings");
            }}
          >
            <img
              src={backArrow}
              alt={"back arrow icon"}
              className={classes.backArrow}
            />
            <span className={classes.titleCenter}>
              <span className={classes.settingsTitle}>Contact us</span>
            </span>
          </div>
          <div>
            <div className={classes.textHolder}>
              <img src={contactUs} alt={"contact us"} />
              <h1>Send us a message</h1>
              <div className={classes.introText}>We will come back to you.</div>
            </div>
            <form onSubmit={submitHandler} className={classes.loginForm}>
              <input
                type={"text"}
                placeholder={"Your name"}
                ref={name}
                className={"inputField"}
              />
              <textarea
                type={"text"}
                id="passwordTwo"
                placeholder={"Your message"}
                ref={message}
                className={`inputField ${classes.textarea}`}
                style={{ height: "40vw" }}
              />

              <div className={classes.signInButtonHolder}>
                <Button type="submit">Send</Button>
                {fieldEmpty && (
                  <span style={{ color: "red" }}>
                    Please fill out all fields
                  </span>
                )}
              </div>
            </form>
          </div>
        </>
      ) : (
        <Confirmation />
      )}
    </m.div>
  );
};

export default ContactUs;
